<template>
  <section
    class="menu"
  >
    <menu-overlay
      v-if="showOverlay"
      @mousedown.stop=""
      @mouseup.stop=""
      @touchstart.stop=""
      @touchend.stop=""
      @click.stop=""
    />
    <menu-icon-component
      v-show="showMenu"
      class="layout-menu pointer-events-auto"
      :show-exit="showExit"
      :exit-flashing="false"
      :toggle-automatic="tutorialState.settings || keyOpenMenu"
      @on-toggle="onToggle"
      @settings-action="openSettings"
      @instructions-action="toggleInstructions(true)"
      @exit-action="exitOpen = true"
    />
    <instruction-component
      v-if="instructionsState.showInstructions"
      :is-mobile="isMobile"
      @close-instructions="toggleInstructions(false)"
    />
    <exit-discipline-modal
      v-if="exitOpen"
      :is-training="isTraining"
      :is-tutorial="isTutorial"
      class="pointer-events-auto"
      @yes="actionExit"
      @back="closeExit"
    />
    <settings-modal
      v-if="settingsOpen"
      class="pointer-events-auto"
      :show-graphics-settings="!isAppWsm"
      show-controls-mobile
      @close="closeSettings"
      @settings-change="setThings"
    />
  </section>
</template>

<script lang="ts">
import {
  Menu,
  ExitDisciplineModal,
  SettingsModal,
  MenuOverlay,
  gameSettingsState,
  gameState,
  loadingCircleState
} from '@powerplay/core-minigames-ui'
import InstructionComponent from './InstructionComponent/index.vue'
import {
  game,
  requestManager,
  gameStats,
  settings,
  SettingsTypes,
  modes,
  tutorialManager
} from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import {
  inputsState,
  instructionsState,
  tuckState,
  type InputsState,
  tutorialState
} from '@/stores'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MenuSection',
  components: {
    MenuIconComponent: Menu,
    ExitDisciplineModal,
    SettingsModal,
    InstructionComponent,
    MenuOverlay
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    },
    isAppWsm: {
      type: Boolean,
      required: true
    },
    showOverlay: {
      type: Boolean,
      required: false
    }
  },
  emits: ['toggle-ui'],
  data() {

    return {
      settingsOpen: false,
      exitOpen: false,
      showExit: true,
      keyOpenMenu: false,
      inputsState: inputsState(),
      instructionsState: instructionsState(),
      tutorialState: tutorialState(),
    }

  },
  computed: {
    showMenu() {

      return !(this.settingsOpen || this.exitOpen || instructionsState().showInstructions)

    },
    isTraining() {

      return modes.isTrainingMode()

    },
    isTutorial() {

      return modes.isTutorial()

    }
  },
  watch: {
    inputsState: {

      handler(value: InputsState) {

        if (value.exitPressed) {

          this.keyOpenMenu = !this.keyOpenMenu

          if (this.settingsOpen) {

            this.keyOpenMenu = false
            this.closeSettings()

          }
          if (this.exitOpen) {

            this.keyOpenMenu = false
            this.closeExit()

          }
          if (instructionsState().showInstructions) {

            this.keyOpenMenu = false
            this.toggleInstructions(false)

          }

        }

      },
      deep: true

    }
  },
  methods: {
    actionExit(): void {

      // ak sme uz ukoncovali, tak potom redirectujeme
      if (gameStats.wasExitedGame()) {

        requestManager.redirect()
        return

      }

      gameStats.setExitedGame(true)
      disciplinePhasesManager.prematureEnded = true
      this.exitOpen = false

      this.onToggle(this.exitOpen)
      game.prematureFinishGame(disciplinePhasesManager.disciplinePrematureEnd)

      gameState().isPrematureEnd = true

      if (modes.isTutorial()) {

        loadingCircleState().isActive = true

      }

    },
    closeExit(): void {

      this.exitOpen = false
      this.onToggle(this.exitOpen)

    },
    closeSettings(): void {

      this.settingsOpen = false
      this.setThings()
      settings.saveValues()
      tuckState().isTuck = false
      tutorialManager.blockInputsManually()
      this.onToggle(this.settingsOpen)

    },
    openSettings(): void {

      gameSettingsState().graphicsSettings = settings.getSetting(SettingsTypes.quality)

      this.settingsOpen = true

    },
    toggleInstructions(value: boolean): void {

      instructionsState().showInstructions = value
      if (!value) {

        this.onToggle(value)

      }

    },
    setThings(): void {

      settings.update('quality', gameSettingsState().graphicsSettings)
      settings.update('sounds', gameSettingsState().volume ? 1 : 0)
      settings.update('qualityAuto', gameSettingsState().graphicsAuto ? 1 : 0)
      settings.update('isLeft', gameSettingsState().isLeft ? 1 : 0)
      settings.update('controlsType', gameSettingsState().controlsType)

    },
    onToggle(isOpen: boolean): void {

      if (this.settingsOpen || instructionsState().showInstructions || this.exitOpen) return
      this.keyOpenMenu = isOpen
      this.$emit('toggle-ui', this.keyOpenMenu)

      if (!isOpen) tutorialState().settings = false

    }
  }
})

</script>

    <style scoped lang="less">
    .layout-menu {
        position: absolute;
        top: 1%;
        right: 1%;
        z-index: 1;
    }
    </style>

