<template>
  <div class="positioner">
    <arrow-animation
      v-if="showArrows"
      position="bottom"
      style="position: absolute; left: 2%; width: 320px; height: 156px; bottom: 12%; transform-origin: 0 100%;"
    />
    <tutorial-keyboard-keys
      v-if="showArrows"
      type="arrow-keys"
      :text="$t('webInfoLeft') + ' / ' + $t('webInfoRight')"
      is-scaled
      class="keyboard-side-keys"
    >
      <section
        class="tutorial-keyboard-keys-wrapper"
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="left"
          letter="a"
          glow
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="right"
          letter="d"
          glow
        />
      </section>
    </tutorial-keyboard-keys>
    <arrow-animation
      v-if="showSharp"
      position="bottom"
      style="position: absolute; right: 2%; width: 602px; height: 146px; bottom: 12%; transform-origin: 100% 100%;"
    />
    <tutorial-keyboard-keys
      v-if="showSharp"
      type="shift"
      :text="$t('hardCornering')"
      class="keyboard-space"
      is-scaled
    >
      <section
        class="shift-key-wrapper"
      >
        <tutorial-keyboard-key
          type="shift"
          letter=""
          glow
        />
        <div class="plus">
          +
        </div>
        <tutorial-keyboard-key
          type="arrow"
          rotation="left"
          letter="a"
          glow
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="right"
          letter="d"
          glow
        />
      </section>
    </tutorial-keyboard-keys>
    <arrow-animation
      v-if="showSpace"
      position="bottom"
      style="position: absolute; right: 2%; width: 310px; height: 146px; bottom: 12%; transform-origin: 100% 100%;"
    />
    <tutorial-keyboard-keys
      v-if="showSpace"
      type="space"
      :text="$t('webInfoStart')"
      class="keyboard-space"
      is-scaled
    >
      <tutorial-keyboard-key
        type="space"
        glow
      />
    </tutorial-keyboard-keys>
  </div>
</template>

<script lang="ts">
import {
  ArrowAnimation,
  TutorialKeyboardKeys,
  TutorialKeyboardKey
} from '@powerplay/core-minigames-ui'
import { tutorialState } from '@/stores'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TutorialInfoKeys',
  components: {
    ArrowAnimation,
    TutorialKeyboardKeys,
    TutorialKeyboardKey
  },
  computed: {
    isControlsTypeAutoTuck() {

      return true

    },
    showSpace() {

      return tutorialState().showButtonStart

    },
    showArrows() {

      return tutorialState().showButtonsMovement

    },
    showSharp() {

      return tutorialState().showSharpMovement

    }
  }
})
</script>

<style lang="less" scoped>
    .positioner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 25%;

        .keyboard-side-keys {
            transform-origin: 0 100%;
            position: absolute;
            left: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                height: 100px;
            }
        }

        .keyboard-space {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;
        }

        .keyboard-shift {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;
        }

        .shift-key-wrapper {
            display: flex;
            align-items: center;
            gap: 20px;
            .plus {
                width: 32px;
                height: 38px;
                font-family: Roboto;
                font-size: 72px;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #233441;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 10px;
            }
        }
    }
</style>
