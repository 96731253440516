import {
  blurState,
  tutorialState
} from '@/stores'
import {
  SectionNames,
  AudioNames
} from '../../types'
import { tutorialDefaultStates } from './TutorialDefaultState'
import {
  audioManager,
  tutorialManager,
  TutorialMessageColors
} from '@powerplay/core-minigames'
import {
  loadingState,
  tutorialCoreState
} from '@powerplay/core-minigames-ui'

/**
 * Informacie pre UI priapdne kontrolne prvky na zmenu UI roznych taskov
 */
export class TutorialUIChange {

  /** Hack lebo nulty task nespusta intro */
  private hack = false

  /** Meno prave beziacej sekcie */
  private actualSectionName: SectionNames | undefined

  /** Samotny stav ktory chceme vo roznych krokoch tutorialu */
  private uiState = {
    [SectionNames.mobileSidePick]: () => {

      console.log('SECTION FIRST')
      if (!this.hack) {

        // document.getElementsByTagName('canvas')[0].classList.add('blur-class')
        blurState().isActive = true
        this.hack = true

      }
      this.setMobile(true)
      // dame prec loading
      loadingState().showLoading = false

    },
    [SectionNames.startSection]: () => {

      this.setMobile(false)
      this.setMessage(true, 'tutorialText6-1')
      this.setAnne(true)
      // dame prec loading (pre web)
      loadingState().showLoading = false

      audioManager.play(AudioNames.commentIntro)

    },
    [SectionNames.startSectionSecond]: () => {

      this.setMessage(true, 'tutorialText6-2', undefined, true)
      this.setAnne(true, false)
      tutorialState().showButtonStart = true

    },
    [SectionNames.startSectionThird]: () => {

      // zmenime store - podla storeu sa zmeny UI
      this.setMessage(false, '')
      this.setAnne(false)

    },
    [SectionNames.startSectionFourth]: () => {

      this.setMessage(true, 'tutorialText6-3', undefined, true)
      tutorialState().showButtonsMovement = true
      // this.setAnne(true)

    },
    [SectionNames.startSectionFifth]: () => {

      this.setMessage(false, '')
      this.setAnne(false)
      this.resetTypeWrite()
      // zmenime store - podla storeu sa zmeny UI

    },
    [SectionNames.startSectionSixth]: () => {

      // zmenime store - podla storeu sa zmeny UI

    },
    [SectionNames.startSectionSeventh]: () => {

      // zmenime store - podla storeu sa zmeny UI

    }
  }

  /**
   * Zena UI podla aktualnej sekcie
   * @param sectionName - Meno aktualnej sekcie
   */
  private changeUi(sectionName: SectionNames): void {

    this.uiState[sectionName]()

  }

  /**
   * Inicializovanie
   */
  public init(): void {

    // Ak bude treba.

  }

  /**
   * Hlavna logika ui zmeny
   */
  private tutorialUILogic(): void {

    const sectionName = tutorialManager.getActualSectionName() as SectionNames
    if (sectionName && sectionName !== this.actualSectionName) {

      this.changeUi(sectionName)
      this.actualSectionName = sectionName

    }

  }

  /**
   * Public metoda do game loopu
   */
  public update(): void {

    tutorialDefaultStates.update()
    this.tutorialUILogic()

  }

  public setMobile(show: boolean): this {

    tutorialState().mobile = show
    return this

  }

  /**
   * Resetnutie typewrite
   */
  private resetTypeWrite(): void {

    tutorialManager.setTypeWriting(true)
    tutorialCoreState().typeWriter = true

  }

  public setAnne(
    showAnne: boolean,
    isRight = false
  ): this {

    tutorialState().anne = {
      showAnne,
      isRight
    }
    return this

  }

  public setMessage(
    showMessage: boolean,
    message = '',
    color = TutorialMessageColors.blank,
    offset = false
  ): this {

    this.resetTypeWrite()
    tutorialState().tutorialMessage = {
      showMessage,
      message,
      color,
      offset
    }
    return this

  }

}

export const tutorialUIChange = new TutorialUIChange()
