import { gatesManager } from '@/app/GatesManager'
import {
  trainingManager,
  modes
} from '@powerplay/core-minigames'
import { Tasks } from '../../types'
import { trainingState } from '@/stores'

/**
 * Trieda pre treningove ulohy
 */
export class TrainingTasks {

  /** Pocet missnutych branok */
  private missedGatesCount = 0

  /** Pocet checkpointov */
  private checkPointsPassed = 0

  /** Celkvoy pocet checkpointov */
  private readonly ALL_CHECKPOINTS = 20

  /** Mnozstvo taskov */
  private readonly NUMBER_OF_TASKS = 3

  /**
   * Inicializovanie treningu
   */
  public initTraining(): void {

    trainingManager.setNumberOfTasks(this.NUMBER_OF_TASKS)

  }

  /**
   * Hlavna metoda ktoru volame pri pouziti trainingovych Taskov
   * @param task - Typu Tasks, typ ulohy ktorej hodnoty sa zadavaju
   * @param valueToCount - hodnota ktora sa zadava
   */
  public countTaskValue(task: Tasks, valueToCount: number): void {

    if (!modes.isTrainingMode()) return

    if (task === Tasks.missedGates) this.countMissedGates(valueToCount)
    if (task === Tasks.checkpoints) this.countPassedCheckpoints(valueToCount)

  }

  /**
   * Metoda na vyhodnotenie branky
   * @param valueToCount - Hodnota branky
   */
  private countMissedGates(valueToCount: number): void {

    this.missedGatesCount = valueToCount

    this.changeUI(Tasks.missedGates, this.getMissedGatesPercent())

  }

  /**
   *  Metoda na vyhodnotenie letu
   * @param valueToCount - Hodnota skoku
   */
  private countPassedCheckpoints(valueToCount: number): void {

    this.checkPointsPassed = valueToCount

    this.changeUI(Tasks.checkpoints, this.getCheckpointsPassedPercent())

  }

  /**
   * Hlavna privatna metoda, ktora riesi UI zmeny a trainingManager classu
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  public saveTaskValue(name: Tasks, value: number): void {

    if (!modes.isTrainingMode()) return

    value = Math.ceil(value * 10000) / 10000

    this.changeUI(name, value)
    trainingManager.setTaskValue(name, value)

  }

  /**
   * Zmena UI
   * @param name - meno tasku
   * @param value - Vyhodnotena hodnota tasku
   */
  private changeUI(name: Tasks, value: number) {

    if (!modes.isTrainingMode()) return
    console.log(`UI zmeny ${name} ma hodnotu ${value}`)
    const percent = Math.ceil(value * 100)
    const tpTask = Math.ceil(Math.round((trainingManager.getTpPerTask() * value * 10) + Number.EPSILON) / 10)
    console.log('tp task:', tpTask)

    if (tpTask !== undefined) {

      trainingState().editTask({
        text: `trainingTask6-${name}`,
        percent: String(percent),
        points: String(tpTask),
        showPoints: true
      })

    }

  }

  /**
   * Vratenie poctu percent za minute branky
   * @returns Percenta
   */
  public getMissedGatesPercent(): number {

    let normalizedMissedGates = 1 - (this.missedGatesCount * 0.05)
    if (normalizedMissedGates < 0) normalizedMissedGates = 0

    return normalizedMissedGates

  }

  /**
   * Vratenie poctu percent za prejdene checkpointy
   * @returns Percenta
   */
  public getCheckpointsPassedPercent(): number {

    const normalizedCheckpoints = this.checkPointsPassed / this.ALL_CHECKPOINTS

    return normalizedCheckpoints

  }

  /**
   * Ulozenie poslednych uloh
   */
  public saveLastTasksValues(): void {

    if (!modes.isTrainingMode()) return

    // ak mame predcasne ukoncenie, tak musime pozriet, ci neostali nejake neprejdene branky
    this.missedGatesCount = gatesManager.getTotalMissedGates()
    this.saveTaskValue(Tasks.missedGates, this.getMissedGatesPercent())
    this.missedGatesCount = 0

    this.saveTaskValue(Tasks.checkpoints, this.getCheckpointsPassedPercent())
    this.checkPointsPassed = 0

    console.log('FINAL DATA SENT')

  }

  /**
   * Reset
   */
  public reset(): void {

    trainingManager.resetAll()
    this.checkPointsPassed = 0
    this.missedGatesCount = 0

  }

}

export const trainingTasks = new TrainingTasks()
