import {
  game,
  THREE,
  type CollisionEvent,
  CANNON,
  type CannonNamedBody,
  timeManager,
  audioManager,
  modes,
  gsap,
  AnimationsManager,
  CallbackAnimationTypes,
  type BatchingData,
  fpsManager
} from '@powerplay/core-minigames'
import type { FinishPhaseManager } from './phases/FinishPhaseManager'
import { disciplinePhasesManager } from './phases/DisciplinePhasesManager'
import {
  AudioNames,
  TriggersGatesTypes,
  DisciplinePhases,
  Tasks,
  TutorialEventType,
  TutorialObjectiveIds,
  AudioGroups,
  GateAnimationsNames,
  type TriggersGatesAll,
  ModelsNames,
  GateColor
} from './types'
import { SplitTimeManager } from './SplitTimeManager'
import {
  audioGameConfig,
  gameConfig,
  gateAnimationsConfig,
  gatesConfig
} from './config'
import { trainingTasks } from './modes/training/TrainingTasks'
import { tutorialFlow } from './modes/tutorial/TutorialFlow'
import { tutorialObjectives } from './modes/tutorial/TutorialObjectives'
import { player } from './player'
import {
  gatesState,
  shinyTextsState
} from '@/stores'
/**
 * Trieda pre spravu branok
 */
export class GatesManager {

  /** pocet branok */
  public gatesCount = 0

  /** Na ktorych indexoch branok bude ukazana rychlost */
  private speedMeterGates: number[] = []

  /** Index aktualnej branky */
  public activeGateIndex = 0

  /** Triggery */
  public triggers: CannonNamedBody[] = []

  /** pocitatlo zle prejdenych branok */
  public failedCounter = 0

  /** pocitadlo vsetkych zle prejdenych branok kvoli tutorial logom */
  public totalFailedCounter = 0

  /** pocitatlo dobre prejdenych branok */
  public successCounter = 0

  /** bol spusteny trigger */
  public needsUpdate = {
    triggered: false,
    success: false
  }

  /** System medzicasov */
  public splitTimeManager!: SplitTimeManager

  /** Normala na poziciu */
  private readonly POSITIONING_NORMAL = new THREE.Vector3(1, 0, 0)

  /** Rotation help object for animations */
  private rotationHelpObject = new THREE.Object3D()

  /** docasny hack pre bells volume */
  private bellsAudioObject = { volume: 0 }

  /** Tween objekt pre audio */
  private audioTween!: gsap.core.Tween

  /** Pomocny objekt */
  private tempObject = new THREE.Object3D()

  /** Ci uz bola prejdena posledna branka */
  private passedFinishGate = false

  /** animacne manazery lavych branok */
  public animationsManagersL: AnimationsManager[] = []

  /** animacne manazery pravych branok */
  public animationsManagersR: AnimationsManager[] = []

  /** callback po ukonceni animacie */
  private animEndCallback?: () => unknown

  /** animovane branky L */
  private ANIMATED_GATES_L: THREE.Object3D[] = []

  /** animovane branky R */
  private ANIMATED_GATES_R: THREE.Object3D[] = []

  /** Nazvy meshov animovanych branok */
  public readonly ANIMATED_GATE_MESH_LEFT_NAME = 'Branka_0L'
  public readonly ANIMATED_GATE_MESH_RIGHT_NAME = 'Branka_0R'

  /** vyska triggrov */
  private readonly TRIGGER_HEIGHT = 50

  /** sirka triggrov */
  private readonly TRIGGER_WIDTH = 50

  /** sirka animation triggera */
  private readonly TRIGGER_ANIMATION_WIDTH = 80

  /** Forward pos shift */
  private readonly FORWARD_TRIGGER_DIST = 1

  /** hrubka triggeru */
  private readonly TRIGGER_DEPTH = 0.2

  /** Aka najvacsia vzdialenost je potrebna pre koliziu s brankou */
  private readonly COLLISION_FLAG_DISTANCE = 1.2

  /** nazov branky pouzivanej na batchovanie */
  private readonly GATE_NAME_LEFT = { 0: 'Branka0LEFT',
    1: 'Branka1LEFT' }

  /** nazov branky pouzivanej na batchovanie */
  private readonly GATE_NAME_RIGHT = { 0: 'Branka0RIGHT',
    1: 'Branka1RIGHT' }

  /** ci su zapnute animovane branky */
  private readonly ANIMATION_ENABLED = true

  /** koef vzdialenosti medzi brankami a fail triggerom */
  private readonly BEHIND_TRIGGER_DIST = -3

  /** vektor na vypocty aby sme si ho nemuseli stale vytvarat */
  private readonly UP_VECTOR = new THREE.Vector3(0, 1, 0)

  /** vektor kde posuvame nechcene veci aby sme si ho nemuseli stale vytvarat */
  private readonly AWAY_VECTOR = new THREE.Vector3(0, -100, 0)

  /**
   * Zakladny set up
   * @param splitData - Split data z init requestu
   */
  public setUp(splitData: number[]): void {

    this.splitTimeManager = new SplitTimeManager()
    this.splitTimeManager.setBestSplit(splitData)

    this.gatesCount = gatesConfig.count
    this.speedMeterGates = gatesConfig.speedmeterOnGate

  }

  /**
   * Vytvorenie zakladnych veci pre manager
   */
  public create(): void {

    // triggre pre prechadzanie branky
    this.createTrigger(TriggersGatesTypes.afterFlags, this.TRIGGER_WIDTH)
    this.createTrigger(TriggersGatesTypes.middle1, this.TRIGGER_WIDTH)
    this.createTrigger(TriggersGatesTypes.middle2, this.TRIGGER_WIDTH)
    this.createTrigger(TriggersGatesTypes.animationTrigger, this.TRIGGER_ANIMATION_WIDTH)
    this.activateGate()
    this.initAnimationManagers()

  }

  /**
   * inicializujem animacneho manazera
   */
  private initAnimationManagers(): void {

    if (!this.ANIMATION_ENABLED) return

    this.ANIMATED_GATES_L[GateColor.red] = game.getObject3D(ModelsNames.gatesLeft)
    this.animationsManagersL[GateColor.red] = this.createAndSetAnimationManager(
      this.ANIMATED_GATES_L[GateColor.red],
      ModelsNames.gatesLeft
    )

    this.ANIMATED_GATES_L[GateColor.blue] = game.cloneSkeleton(this.ANIMATED_GATES_L[GateColor.red])
    const gateLeft0 = this.ANIMATED_GATES_L[GateColor.blue].getObjectByName('Branka0LEFT')
    if (gateLeft0) gateLeft0.visible = false
    const gateLeft1 = this.ANIMATED_GATES_L[GateColor.blue].getObjectByName('Branka1LEFT')
    if (gateLeft1) gateLeft1.visible = false
    game.scene.add(this.ANIMATED_GATES_L[GateColor.blue])
    this.animationsManagersL[GateColor.blue] = this.createAndSetAnimationManager(
      this.ANIMATED_GATES_L[GateColor.blue],
      ModelsNames.gatesLeft
    )
    game.changeUVs(this.ANIMATED_GATES_L[GateColor.blue]
      .getObjectByName(this.ANIMATED_GATE_MESH_LEFT_NAME) as THREE.Mesh)

    this.ANIMATED_GATES_R[GateColor.red] = game.getObject3D(ModelsNames.gatesRight)
    this.animationsManagersR[GateColor.red] = this.createAndSetAnimationManager(
      this.ANIMATED_GATES_R[GateColor.red],
      ModelsNames.gatesRight
    )

    this.ANIMATED_GATES_R[GateColor.blue] = game.cloneSkeleton(this.ANIMATED_GATES_R[GateColor.red])
    // naklonovane branky musime schovat
    const gateRight0 = this.ANIMATED_GATES_R[GateColor.blue].getObjectByName('Branka0RIGHT')
    if (gateRight0) gateRight0.visible = false
    const gateRight1 = this.ANIMATED_GATES_R[GateColor.blue].getObjectByName('Branka1RIGHT')
    if (gateRight1) gateRight1.visible = false
    game.scene.add(this.ANIMATED_GATES_R[GateColor.blue])
    this.animationsManagersR[GateColor.blue] = this.createAndSetAnimationManager(
      this.ANIMATED_GATES_R[GateColor.blue],
      ModelsNames.gatesRight
    )
    game.changeUVs(this.ANIMATED_GATES_R[GateColor.blue]
      .getObjectByName(this.ANIMATED_GATE_MESH_RIGHT_NAME) as THREE.Mesh)

  }

  /**
   * Vytvorenie a nastavenie animacneho managera
   * @param gateObject - Objekt branky
   * @param gateName - Meno branky
   * @returns Animacny manager
   */
  private createAndSetAnimationManager(
    gateObject: THREE.Object3D,
    gateName: string
  ): AnimationsManager {

    const animationsManager = new AnimationsManager(
      gateObject,
      gateAnimationsConfig,
      game.animations.get(gateName),
      gameConfig.defaultAnimationSpeed,
      fpsManager
    )
    animationsManager.setDefaultSpeed(gameConfig.defaultAnimationSpeed)
    animationsManager.resetSpeed()

    return animationsManager

  }

  /**
   * Aktualizovanie animacii hraca
   * @param delta - Delta
   */
  public updateAnimations(delta: number): void {

    if (!this.ANIMATION_ENABLED) return

    this.animationsManagersL[GateColor.red].update(delta)
    this.animationsManagersL[GateColor.blue].update(delta)
    this.animationsManagersR[GateColor.red].update(delta)
    this.animationsManagersR[GateColor.blue].update(delta)

  }

  /**
   * po ktorej branke davame finish
   */
  private get finishGate(): number {

    return this.gatesCount - 1

  }

  /**
   * Vytvorenie konkretneho triggera
   * @param type - Typ triggera z mnoziny
   * @param width - Sirka
   */
  private createTrigger(
    type: TriggersGatesAll,
    width: number
  ): void {

    const physicsBody = new CANNON.Body({
      mass: 0,
      shape: new CANNON.Box(new CANNON.Vec3(width, this.TRIGGER_HEIGHT, this.TRIGGER_DEPTH)),
      material: new CANNON.Material('Trigger'),
      isTrigger: true,
      collisionFilterGroup: 2,
      collisionFilterMask: 2
    }) as CannonNamedBody
    physicsBody.name = `gate-${type}`

    game.physics.addBody(physicsBody)

    physicsBody.addEventListener('collide', (e: CollisionEvent) => {

      if (e.body.name === 'collisionPlayerBodyGates'/* && event.match('start') */) {

        this.triggerCallback(type)

      }

    })

    this.triggers[type] = physicsBody

  }

  /**
   * Skontrolovanie audia
   * @param gateNumber - Cislo branky
   */
  private checkAudio(gateNumber: number): void {

    if (gatesConfig.audioGatesStartBells.includes(gateNumber)) {

      audioManager.play(AudioNames.audienceBells, undefined, undefined, 0)
      if (this.audioTween) this.audioTween.kill()
      this.audioTween = gsap.to(this.bellsAudioObject, {
        volume: 0.75,
        duration: 1,
        ease: 'none'
      })

    }

    if (gatesConfig.audioGatesStopBells.includes(gateNumber)) {

      if (this.audioTween) this.audioTween.kill()
      this.audioTween = gsap.to(this.bellsAudioObject, {
        volume: 0,
        duration: 1,
        ease: 'none',
        onComplete: () => {

          audioManager.stopAudioByName(AudioNames.audienceBells)

        }
      })

    }

  }

  /**
   * Aktualizovanie veci kazdy prechod brankou
   * @param gateAdvance - True, ak sa preslo brankou
   */
  public update(gateAdvance: boolean): void {

    if (!gateAdvance) return

    this.needsUpdate.triggered = false
    this.gateAdvance(this.needsUpdate.success)
    this.playCommentatorAudio(!this.needsUpdate.success)

    this.checkAudio(this.activeGateIndex - 1)

    this.splitTimeManager.checkActualSplit(this.activeGateIndex - 1)

  }

  /**
   * zahrame komentatora
   *
   * @param isMiss - ci sme netrafili branky
   */
  private playCommentatorAudio(isMiss: boolean): void {

    let audio = ''

    if (isMiss && !audioManager.isAudioGroupPlaying(AudioGroups.commentators)) {

      audio = AudioNames.commentMissedGate

    }

    if (this.activeGateIndex === this.finishGate - audioGameConfig.commentBeforeFinish) {

      audio = AudioNames.commentBeforeFinish

    }

    if (!audio) return

    audioManager.stopAudioByGroup(AudioGroups.commentators)
    audioManager.play(audio)

  }

  /**
   * ak sme na zadefinovanej branke zobrazime rychlost
   */
  private showActualSpeed(): void {

    if (!this.speedMeterGates.includes(this.activeGateIndex)) return

    disciplinePhasesManager.showActualSpeed()

  }

  /**
   * Callback pre trigger po kolizii
   * @param type - Typ triggeru z mnoziny
   */
  private triggerCallback = (type: TriggersGatesAll): void => {

    // console.log(`TRIGGER gates ${type}`)

    if (type === TriggersGatesTypes.animationTrigger) {

      if (!this.ANIMATION_ENABLED) return
      this.animateGate()
      return

    }

    this.needsUpdate.triggered = true

    // toto prehravame, aj ked si nepresiel stredom
    if (this.activeGateIndex === this.finishGate - gatesConfig.gateNumberTriggerAudienceHype) {

      audioManager.play(AudioNames.audienceHype)

    }

    if (
      (type !== TriggersGatesTypes.middle1) &&
            (type !== TriggersGatesTypes.middle2)
    ) {

      this.needsUpdate.success = false
      return

    }

    this.needsUpdate.success = true
    // ciel
    if (this.activeGateIndex === this.finishGate && !this.passedFinishGate) {

      this.passedFinishGate = true
      disciplinePhasesManager.getDisciplinePhaseManager(DisciplinePhases.game).finishPhase()

      audioManager.stopAudioByName(AudioNames.skiingLoop)

    }

    this.showActualSpeed()

  }

  /**
   * Metoda riesiaca animaciu gateov
   */
  private animateGate(): void {

    const { gateLeftData, gateRightData } = this.getActualGates()
    const playerPositionVector = player.getPosition()

    const {
      leftGateLeftRodPosition, leftGateMiddlePosition, leftGateRightRodPosition,
      rightGateLeftRodPosition, rightGateMiddlePosition, rightGateRightRodPosition
    } = this.calculateRodPositions(gateLeftData, gateRightData)

    const {
      distanceLeftGateLeftRod, distanceLeftGateMiddle, distanceLeftGateRightRod,
      distanceRightGateLeftRod, distanceRightGateMiddle, distanceRightGateRightRod
    } = this.getDistanceFromRods(
      playerPositionVector,
      leftGateMiddlePosition,
      leftGateLeftRodPosition,
      leftGateRightRodPosition,
      rightGateLeftRodPosition,
      rightGateMiddlePosition,
      rightGateRightRodPosition
    )

    this.decideAnimation(
      distanceLeftGateLeftRod,
      distanceLeftGateMiddle,
      distanceLeftGateRightRod,
      distanceRightGateLeftRod,
      distanceRightGateMiddle,
      distanceRightGateRightRod
    )

  }

  /**
   * Rozhodujuca funkcia ohladom spustenia animacii
   * @param distanceLeftGateLeftRod - vzdialenost
   * @param distanceLeftGateRightRod - vzdialenost
   * @param distanceRightGateLeftRod - vzdialenost
   * @param distanceRightGateRightRod - vzdialenost
   */
  private decideAnimation(
    distanceLeftGateLeftRod: number,
    distanceLeftGateMiddle: number,
    distanceLeftGateRightRod: number,
    distanceRightGateLeftRod: number,
    distanceRightGateMiddle: number,
    distanceRightGateRightRod: number
  ) {

    const distanceArray = [
      distanceLeftGateLeftRod,
      distanceLeftGateMiddle,
      distanceLeftGateRightRod,
      distanceRightGateLeftRod,
      distanceRightGateMiddle,
      distanceRightGateRightRod
    ]

    if (!distanceArray.some(distance => distance < this.COLLISION_FLAG_DISTANCE)) return
    const min = Math.min(...distanceArray)
    const index = distanceArray.indexOf(min)

    switch (index) {

      case 0:
        this.playAnimation(GateAnimationsNames.left, true)
        break
      case 1:
        this.playAnimation(GateAnimationsNames.straight, true)
        break
      case 2:
        this.playAnimation(GateAnimationsNames.right, true)
        break
      case 3:
        this.playAnimation(GateAnimationsNames.left, false)
        break
      case 4:
        this.playAnimation(GateAnimationsNames.straight, false)
        break
      case 5:
        this.playAnimation(GateAnimationsNames.right, false)
        break
      default:
        console.error('Error this should not be happening')

    }
    audioManager.stopAudioByName(AudioNames.gateTouch)
    audioManager.play(AudioNames.gateTouch)

  }

  /**
   * Vygeneruje pozicie na odhad vzdialenosti od vlajocky
   * @param gateLeftData - udaje z batchingu pre vlajocku lavu
   * @param gateRightData - udaje z batchingu pre vlajocku pravu
   * @returns object pozicii na vypocet
   */
  private calculateRodPositions(gateLeftData: BatchingData, gateRightData: BatchingData) {

    // leftgate ma offset pri pravej nohe
    const leftGateRightRodPosition = gateLeftData.offset
    const leftRotation = gateLeftData.rotation
    const leftGateLeftRodPosition = this.calculatePosition(
      leftGateRightRodPosition,
      leftRotation,
      -1
    )
    const leftGateMiddlePosition = this.calculatePosition(
      leftGateRightRodPosition,
      leftRotation,
      -0.5
    )

    // rightgate ma offset pri lavej nohe
    const rightGateLeftRodPosition = gateRightData.offset
    const rightRotation = gateRightData.rotation
    const rightGateRightRodPosition = this.calculatePosition(
      rightGateLeftRodPosition,
      rightRotation,
      -1
    )
    const rightGateMiddlePosition = this.calculatePosition(
      rightGateLeftRodPosition,
      rightRotation,
      -0.5
    )

    return {
      leftGateLeftRodPosition,
      leftGateMiddlePosition,
      leftGateRightRodPosition,
      rightGateLeftRodPosition,
      rightGateMiddlePosition,
      rightGateRightRodPosition
    }

  }

  /**
   * Vypocet medzipozicie objektu
   * @param position - pozicia objektu
   * @param rotation - rotacia objektu
   * @param offset - vzdialenost objektu
   * @returns vector pozicie noveho objectu
   */
  private calculatePosition(position: THREE.Vector3, rotation: THREE.Vector3, offset: number) {

    this.rotationHelpObject.position.set(position.x, position.y, position.z)
    this.rotationHelpObject.rotation.set(rotation.x, rotation.y, rotation.z)
    this.rotationHelpObject.translateOnAxis(this.POSITIONING_NORMAL, offset) // konstanta
    return this.rotationHelpObject.position.clone()

  }

  /**
   * Vypocita realnu vzdialenost hraca od vsetkych styroch rohov
   * @param playerPosition - pozicia hraca
   * @param leftGateLeftRod - pozicia lavej nohy lavej vlajky
   * @param leftGateMiddlePosition - pozicia stredu lavej vlajky
   * @param leftGateRightRod - pozicia pravej nohy lavej vlajky
   * @param rightGateLeftRod - pozicia lavej nohy pravej vlajky
   * @param rightGateMiddlePosition - pozicia stredu  pravej vlajky
   * @param rightGateRightRod - pozicia pravej nohy pravej vlajky
   * @returns Object s realnymi vzdialenostami hraca od roznych bodov
   */
  private getDistanceFromRods(
    playerPosition: THREE.Vector3,
    leftGateLeftRod: THREE.Vector3,
    leftGateMiddlePosition: THREE.Vector3,
    leftGateRightRod: THREE.Vector3,
    rightGateLeftRod: THREE.Vector3,
    rightGateMiddlePosition: THREE.Vector3,
    rightGateRightRod: THREE.Vector3
  ) {

    return {
      distanceLeftGateLeftRod: playerPosition.distanceTo(leftGateLeftRod),
      distanceLeftGateMiddle: playerPosition.distanceTo(leftGateMiddlePosition),
      distanceLeftGateRightRod: playerPosition.distanceTo(leftGateRightRod),
      distanceRightGateLeftRod: playerPosition.distanceTo(rightGateLeftRod),
      distanceRightGateMiddle: playerPosition.distanceTo(rightGateMiddlePosition),
      distanceRightGateRightRod: playerPosition.distanceTo(rightGateRightRod)
    }

  }

  /**
   * Ziska metadata aktualnych ranok
   * @returns Object s udajmi branok
   */
  private getActualGates() {

    const { gateIndex, gateIndexEven } = this.getGateIndexInfo()

    const gateLeftData = game.batchingManager.getObjectData(this.GATE_NAME_LEFT[gateIndexEven ? 0 : 1], gateIndex)
    const gateRightData = game.batchingManager.getObjectData(this.GATE_NAME_RIGHT[gateIndexEven ? 0 : 1], gateIndex)

    return {
      gateIndex,
      gateLeftData,
      gateRightData
    }

  }

  private getGateIndexInfo(): {gateIndex: number, gateIndexEven: boolean} {

    const gateIndex = Math.floor(this.activeGateIndex / 2)

    const gateIndexEven = this.activeGateIndex % 2 === 0

    return { gateIndex,
      gateIndexEven }

  }

  /**
   * Vymenime branku za animovanu a zahrame animaciu
   * @param animation - ktoru animaciu chceme
   * @param isLeftGate - ci chceme animovat lavu branku
   */
  private playAnimation(animation: GateAnimationsNames, isLeftGate: boolean): void {

    if (this.animEndCallback) this.animEndCallback()

    // console.log('playing gate animation:', animation)
    const { gateIndex, gateIndexEven } = this.getGateIndexInfo()
    const gateColor = gateIndexEven ? GateColor.red : GateColor.blue

    const gateName = isLeftGate ?
      this.GATE_NAME_LEFT[gateColor] :
      this.GATE_NAME_RIGHT[gateColor]

    let animationsManager = this.animationsManagersR[gateColor]
    let animatedGate = this.ANIMATED_GATES_R[gateColor]
    if (isLeftGate) {

      animationsManager = this.animationsManagersL[gateColor]
      animatedGate = this.ANIMATED_GATES_L[gateColor]

    }

    const gateData = game.batchingManager.getObjectData(gateName, gateIndex)

    // console.log(animatedGate)
    animatedGate.position.set(gateData.offset.x, gateData.offset.y, gateData.offset.z)
    animatedGate.rotation.set(gateData.rotation.x, gateData.rotation.y, gateData.rotation.z)
    // console.warn(animatedGate.position)
    const gateId = gateIndex
    this.hideBatchedGate(gateName, gateId)

    this.animEndCallback = () => {

      animationsManager.resetActualAnimation()
      this.returnBatchedGate(gateName, gateId, gateColor)
      animationsManager.removeAnimationCallback(animation, CallbackAnimationTypes.end)
      this.animEndCallback = undefined

    }

    animationsManager.addAnimationCallback(
      animation,
      CallbackAnimationTypes.end,
      this.animEndCallback
    )
    animationsManager.changeTo(animation)

  }

  /**
   * Vratime batchovanu branku naspat
   * @param gateName - nazov branky
   * @param gateIndex - index branky
   * @param gateColor - Index poradia branky (kvoli farebnosti)
   */
  private returnBatchedGate(gateName: string, gateIndex: number, gateColor: GateColor): void {

    this.ANIMATED_GATES_L[gateColor].position.set(0, 0, 0)
    this.ANIMATED_GATES_R[gateColor].position.set(0, 0, 0)

    game.batchingManager.shiftYPosition(
      game.scene,
      gateIndex,
      gateName,
      100
    )

  }

  /**
   * schovame batchovanu branku
   * @param gateName - Nazov branky
   * @param gateIndex - Index branky
   */
  private hideBatchedGate(gateName: string, gateIndex: number): void {

    game.batchingManager.shiftYPosition(
      game.scene,
      gateIndex,
      gateName,
      -100
    )

  }

  /**
   * zobrazujeme branky
   */
  private setNextVirtual() {

    if (this.activeGateIndex <= 0) return

    if (this.activeGateIndex % 2 === 0) {

      game.batchingManager.setNextVirtual(game.scene, this.GATE_NAME_LEFT[1])
      game.batchingManager.setNextVirtual(game.scene, this.GATE_NAME_RIGHT[1])
      return

    }

    game.batchingManager.setNextVirtual(game.scene, this.GATE_NAME_LEFT[0])
    game.batchingManager.setNextVirtual(game.scene, this.GATE_NAME_RIGHT[0])

  }

  /**
   * Prejdenie branky
   * @param success - Ci sa preslo brankou dobre alebo nie
   */
  private gateAdvance(success: boolean): void {

    const finishManager = disciplinePhasesManager
      .getDisciplinePhaseManager(DisciplinePhases.finish) as FinishPhaseManager
    finishManager.successfulGates += 1

    this.setNextVirtual()

    this.activeGateIndex += 1

    if (success) {

      console.warn('successful gate')
      this.successCounter += 1

      if (modes.isTutorial() && this.successCounter >= gatesConfig.tutorialGatesObjective) {

        tutorialObjectives.passObjective(TutorialObjectiveIds.gates as string)

      }

    } else {

      console.warn('failed gate')
      this.failedCounter += 1
      this.totalFailedCounter += 1
      timeManager.addPenalty()
      tutorialFlow.eventActionTrigger(TutorialEventType.gateMissEvent)
      shinyTextsState().setTextByName({
        name: 'miss',
        active: true
      })

      gsap.to({}, {
        onComplete: () => {

          shinyTextsState().setTextByName({
            name: 'miss',
            active: false
          })

        },
        duration: 1
      })

    }

    if (this.successCounter + this.totalFailedCounter >= 4) {

      tutorialFlow.eventActionTrigger(TutorialEventType.sharpTurnInfo)

    }

    trainingTasks.countTaskValue(Tasks.missedGates, this.failedCounter)

    this.activateGate()

  }
  /**
   * Zistenie vektoru v smere branky v spojeni s druhou brankou
   * @param gateData - Data hlavnej branky
   * @param secondGateData - Data druhej branky
   * @returns Normalovy a Smerovy vektor
   */
  private getGateVectors(gateData: BatchingData, secondGateData: BatchingData): {
    vectorNormal: THREE.Vector3, vectorDir: THREE.Vector3
  } {

    // nastavime si pomocny objekt na pivot branky s rotaciou
    this.tempObject.position.set(gateData.offset.x, gateData.offset.y, gateData.offset.z)
    this.tempObject.rotation.set(gateData.rotation.x, gateData.rotation.y, gateData.rotation.z)

    // vektor z danej pozicie a rotacie
    const vectorPosRot = this.tempObject.getWorldDirection(new THREE.Vector3())

    // vektor pre smer ku druhej branke
    const vecToOppositeGate = secondGateData.offset.clone().sub(gateData.offset.clone()).normalize()

    // podla predoslych 2 vektorov a otoceni vektora dostaneme normalu smerom hore
    const vectorNormal = new THREE.Vector3().crossVectors(vectorPosRot, vecToOppositeGate).negate().normalize()

    // vektor smeru spojeneho z rotacie branky a spojnice medzi brankami
    const vectorDir = new THREE.Vector3().crossVectors(vecToOppositeGate, vectorNormal)

    // debug zobrazenie vektorov
    if (gatesConfig.debugTriggers.vectors) {

      game.scene.add(new THREE.ArrowHelper(vectorPosRot, gateData.offset, 10, 0xFF00FF))
      game.scene.add(new THREE.ArrowHelper(vecToOppositeGate, gateData.offset, 10, 0x00FFFF))
      game.scene.add(new THREE.ArrowHelper(vectorNormal, gateData.offset, 10, 0x0F0FFF))
      game.scene.add(new THREE.ArrowHelper(vectorDir, gateData.offset, 10, 0x5FF060))

    }

    return { vectorNormal,
      vectorDir }

  }

  /**
   * Zistenie rotacie pre brankovy trigger
   * @param vectorDir - Smerovy vektor branky
   * @param positionOrigin - Pozicia pr etrigger
   * @returns Rotacia
   */
  private getGateTriggerRotation(
    vectorDir: THREE.Vector3,
    vectorNormal: THREE.Vector3,
    positionOrigin: THREE.Vector3
  ): THREE.Euler {

    // nastavime si origin bod
    const originPoint = new THREE.Object3D()
    originPoint.position.set(positionOrigin.x, positionOrigin.y, positionOrigin.z)

    // nastavime si bod, kam sa mame pozerat podla dir vectora
    const lookAtPoint = positionOrigin.add(vectorDir.multiplyScalar(this.BEHIND_TRIGGER_DIST))
    // nastaviem up podla vector normal, aby bol spravny "scew"
    originPoint.up.set(vectorNormal.x, vectorNormal.y, vectorNormal.z)
    originPoint.lookAt(lookAtPoint)

    if (gatesConfig.debugTriggers.meshes) {

      const boxGeometry = new THREE.BoxGeometry(10, 30, 0.2)
      const boxMesh = new THREE.Mesh(
        boxGeometry,
        new THREE.MeshBasicMaterial({ color: Math.random() * 0xffffff })
      )
      boxMesh.position.set(originPoint.position.x, originPoint.position.y, originPoint.position.z)
      boxMesh.rotation.set(originPoint.rotation.x, originPoint.rotation.y, originPoint.rotation.z)
      game.scene.add(boxMesh)

    }

    if (gatesConfig.debugTriggers.points) {

      const geometrySphere = new THREE.SphereGeometry(0.2, 0.2, 0.2)
      const materialSphere = new THREE.MeshBasicMaterial({ color: 0x56FF22 })
      const meshSphereLookAt = new THREE.Mesh(geometrySphere, materialSphere)
      meshSphereLookAt.position.set(lookAtPoint.x, lookAtPoint.y + 1, lookAtPoint.z)
      game.scene.add(meshSphereLookAt)

      const materialSphere2 = new THREE.MeshBasicMaterial({ color: 0x562222 })
      const meshSphereMiddle = new THREE.Mesh(geometrySphere, materialSphere2)
      meshSphereMiddle.position.set(positionOrigin.x, positionOrigin.y, positionOrigin.z)
      game.scene.add(meshSphereMiddle)

    }

    return originPoint.rotation

  }

  /**
   * Aktivuje dalsiu branku a presunie triggre
   */
  private activateGate(): void {

    if (this.activeGateIndex >= this.gatesCount) return

    // UI update
    gatesState().$patch({
      actualGate: this.activeGateIndex + 1,
      successCounter: this.successCounter,
      failedCounter: this.failedCounter
    })

    const { gateLeftData, gateRightData } = this.getActualGates()

    const offsetLeft = gateLeftData.offset.clone()
    const offsetRight = gateRightData.offset.clone()

    const centerPos = new THREE.Vector3(
      (offsetRight.x + offsetLeft.x) / 2,
      (offsetRight.y + offsetLeft.y) / 2,
      (offsetRight.z + offsetLeft.z) / 2
    )
    const midPosRight = new THREE.Vector3(
      2 * offsetRight.x / 3 + offsetLeft.x / 3,
      2 * offsetRight.y / 3 + offsetLeft.y / 3,
      2 * offsetRight.z / 3 + offsetLeft.z / 3
    )
    const midPosLeft = new THREE.Vector3(
      2 * offsetLeft.x / 3 + offsetRight.x / 3,
      2 * offsetLeft.y / 3 + offsetRight.y / 3,
      2 * offsetLeft.z / 3 + offsetRight.z / 3
    )

    let coefGateWidth = gatesConfig.successTriggerWidth
    if (this.activeGateIndex === this.finishGate) coefGateWidth = -10 // ciel radsej natiahneme

    const gateWidthLeft = offsetRight.distanceTo(offsetLeft) / 3
    const shapeMiddleLeft = this.triggers[TriggersGatesTypes.middle1].shapes[0] as CANNON.Box
    shapeMiddleLeft.halfExtents.x = gateWidthLeft - coefGateWidth

    const gateWidthRight = offsetLeft.distanceTo(offsetRight) / 3
    const shapeMiddleRight = this.triggers[TriggersGatesTypes.middle2].shapes[0] as CANNON.Box
    shapeMiddleRight.halfExtents.x = gateWidthRight - coefGateWidth

    // trigger pre pravu branku
    const {
      vectorNormal: rightVectorNormal,
      vectorDir: rightVectorDir
    } = this.getGateVectors(gateRightData, gateLeftData)
    const rightTriggerRotation = this.getGateTriggerRotation(rightVectorDir, rightVectorNormal, midPosRight.clone())

    // trigger pre lavu branku
    const {
      vectorNormal: leftVectorNormal,
      vectorDir: leftVectorDir
    } = this.getGateVectors(gateRightData, gateLeftData)
    const leftTriggerRotation = this.getGateTriggerRotation(leftVectorDir, leftVectorNormal, midPosLeft.clone())

    // bod v strede, aby sme vedeli, kde dat trigger afterFlags
    const averageDirVector = rightVectorDir.clone().add(leftVectorDir)
    const originPointAverage = new THREE.Object3D()
    originPointAverage.position.set(centerPos.x, centerPos.y, centerPos.z)
    // nastavime si bod, kam sa mame pozerat podla dir vectora
    const lookAtPointAverage = centerPos.clone().add(averageDirVector.multiplyScalar(1/* this.BEHIND_TRIGGER_DIST*/))
    originPointAverage.lookAt(lookAtPointAverage)

    // triggre pre prejazd brankou
    this.setPositionTrigger(TriggersGatesTypes.middle1, midPosRight, rightTriggerRotation)
    this.setPositionTrigger(TriggersGatesTypes.middle2, midPosLeft, leftTriggerRotation)
    this.setPositionTrigger(
      TriggersGatesTypes.afterFlags,
      this.activeGateIndex === this.finishGate ?
        this.AWAY_VECTOR : // pri cieli radsej davame fail prec
        lookAtPointAverage,
      originPointAverage.rotation
    )

    const forPostShift = centerPos.clone().multiplyScalar(this.FORWARD_TRIGGER_DIST)
    // Pozicia animacneho triggera
    this.setPositionTrigger(
      TriggersGatesTypes.animationTrigger,
      this.activeGateIndex === this.finishGate ?
        this.AWAY_VECTOR : // pri cieli radsej davame fail prec
        forPostShift,
      originPointAverage.rotation
    )

  }

  /**
   * Nastavime poziciu a rotaciu konkretneho triggeru
   * @param trigger - trigger
   * @param centerPos - pozicia stredu
   * @param rotation - rotacia
   */
  private setPositionTrigger(
    trigger: TriggersGatesAll,
    centerPos: THREE.Vector3,
    rotation: THREE.Euler
  ): void {

    this.triggers[trigger].position.set(centerPos.x, centerPos.y, centerPos.z)
    this.triggers[trigger].quaternion.setFromEuler(
      rotation.x,
      rotation.y,
      rotation.z,
      'XYZ'
    )

  }

  /**
   * Vratenie poctu neprejdenych branok (pri predcasnom ukonceni aj tych buducich)
   * @returns Pocet neprejdenych branok
   */
  public getTotalMissedGates(): number {

    return this.failedCounter + (this.gatesCount - this.activeGateIndex - 1)

  }

  /**
   * Iba debug skipnutie vsetkych branok a natavenie tej poslednej
   */
  public skipToFinishGate(): void {

    this.activeGateIndex = this.finishGate
    this.activateGate()

  }

  public debugAnimation(): void {

    this.playAnimation(GateAnimationsNames.right, true)

  }

  /**
   * reset managera
   */
  public reset(): void {

    this.splitTimeManager = new SplitTimeManager()
    this.activeGateIndex = 0
    this.passedFinishGate = false
    // this.triggers = []
    this.failedCounter = 0
    this.successCounter = 0
    this.needsUpdate = { triggered: false,
      success: false }
    game.batchingManager.reset(game.scene)
    this.activateGate()

  }

}

export const gatesManager = new GatesManager()
