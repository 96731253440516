<template>
  <div class="hud">
    <div class="info">
      <div>Max speed: {{ mainState.maxSpeed.toFixed(2) }}</div>
      <div>VelocityX: {{ mainState.velocityX.toFixed(4) }}</div>
      <div>VelocityY: {{ mainState.velocityY.toFixed(4) }}</div>
      <div>VelocityZ: {{ mainState.velocityZ.toFixed(4) }}</div>
      <div>Uspesne branky: {{ gatesState.successCounter }}</div>
      <div>Neuspesne branky: {{ gatesState.failedCounter }}</div>
      <div>Dalsia branka: {{ gatesState.actualGate }}</div>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import {
  gatesState,
  mainState
} from '@/stores'

export default defineComponent({
  name: 'HUD',
  data() {

    return {
      gatesState: gatesState(),
      mainState: mainState(),
    }

  },
})

</script>

<style lang="less" scoped>
.hud {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .info {
     display: flex;
     flex-direction: column;
  background: black;
  color: white;
  width:  16.666667%;
  }
}
</style>
