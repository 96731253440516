import type { AudioObject } from '@powerplay/core-minigames'
import {
  AudioCategories,
  AudioGroups,
  AudioNames
} from '../types'

export const spriteAudio: AudioObject[] = [
  {
    files: [
      AudioNames.spriteBells
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 0.75,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: true,
    subset: true,
    randomizeSprite: true,
    category: AudioCategories.sprite,
    sprite: {
      'bells_var02': [
        0,
        7600
      ],
      'bells_var021': [
        9000,
        6433.333333333334
      ]
    }
  },
  {
    files: [
      AudioNames.spriteNotLooped
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    enabled: true,
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    loop: false,
    subset: true,
    randomizeSprite: true,
    category: AudioCategories.sprite,
    sprite: {
      'audience_sad': [
        0,
        9997.00680272109
      ],
      'audience_yay': [
        11000,
        8254.69387755102
      ],
      'countdown_beep_long': [
        21000,
        870.6122448979592
      ],
      'countdown_beep_short': [
        23000,
        755.8503401360532
      ],
      'DH_crash': [
        25000,
        1100.0000000000014
      ],
      'DH_gatepass_var03': [
        28000,
        938.2766439909283
      ],
      'DH_gatepass_var031': [
        30000,
        983.4693877551003
      ],
      'DH_gatepass_var032': [
        32000,
        883.083900226758
      ],
      'DH_gatepass_var033': [
        34000,
        1104.761904761908
      ],
      'DH_checkpoint_fail': [
        37000,
        1224.58049886621
      ],
      'DH_checkpoint_good': [
        40000,
        1462.3129251700675
      ],
      'DH_skiing_break': [
        43000,
        6709.002267573695
      ],
      'DH_skiing_jump': [
        51000,
        1201.814058956913
      ],
      'DH_skiing_landing': [
        54000,
        1612.3809523809527
      ]
    }
  },
  {
    files: [
      AudioNames.spriteCommentators
    ],
    startOnRandomPlace: false,
    randomDelayBeforeStartFrom: 0,
    randomDelayBeforeStartTo: 0,
    ignoreModes: [3, 14, 9, 10],
    volume: 1,
    volumeRandom: 0,
    rate: 1,
    rateRandom: 0,
    enabled: true,
    loop: false,
    category: AudioCategories.sprite,
    randomizeSprite: true,
    group: AudioGroups.commentators,
    subset: true,
    sprite: {
      'finish1': [
        0,
        6174.580498866213
      ],
      'finish2': [
        8000,
        7379.569160997733
      ],
      'finish3': [
        17000,
        8431.383219954647
      ],
      'finish4': [
        27000,
        6913.287981859412
      ],
      'missed_gate': [
        35000,
        5384.8752834467105
      ],
      'missed_gate2': [
        42000,
        4219.8412698412685
      ],
      'split_times_deficit_decreased': [
        48000,
        4228.730158730158
      ],
      'split_times_deficit_decreased2': [
        54000,
        3464.263038548751
      ],
      'split_times_deficit_increased': [
        59000,
        3462.8798185941037
      ],
      'split_times_deficit_increased2': [
        64000,
        3563.4240362811765
      ],
      'split_times_first_minus': [
        69000,
        3861.882086167796
      ],
      'split_times_first_minus2': [
        74000,
        4233.74149659864
      ],
      'split_times_first_plus': [
        80000,
        3960.27210884354
      ],
      'split_times_first_plus2': [
        85000,
        2578.2766439909324
      ],
      'split_times_from_green_to_red': [
        89000,
        3656.258503401361
      ],
      'split_times_from_green_to_red2': [
        94000,
        4747.414965986394
      ],
      'split_times_from_red_to_green': [
        100000,
        3658.6167800453495
      ],
      'split_times_from_red_to_green2': [
        105000,
        5000
      ],
      'split_times_lead_decrease': [
        111000,
        4070.0226757369646
      ],
      'split_times_lead_decrease2': [
        117000,
        4295.374149659864
      ],
      'split_times_lead_increase': [
        123000,
        3581.5192743764187
      ],
      'split_times_lead_increase2': [
        128000,
        5254.331065759629
      ]
    }
  }
]