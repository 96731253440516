<template>
  <div class="positioner">
    <tutorial-keyboard-keys
      v-if="showArrows"
      type="arrow-keys"
      :text="$t('webInfoLeft') + ' / ' + $t('webInfoRight')"
      is-scaled
      class="keyboard-side-keys"
    >
      <section
        class="tutorial-keyboard-keys-wrapper"
      >
        <tutorial-keyboard-key
          type="arrow"
          rotation="left"
          letter="a"
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="right"
          letter="d"
        />
      </section>
    </tutorial-keyboard-keys>
    <tutorial-keyboard-keys
      v-if="showSpace"
      type="space"
      :text="$t('webInfoStart')"
      class="keyboard-space"
      is-scaled
    >
      <tutorial-keyboard-key
        type="space"
      />
    </tutorial-keyboard-keys>
    <tutorial-keyboard-keys
      v-if="showSpacePressed"
      type="space-pressed"
      :text="$t('webInfoTuck')"
      class="keyboard-space"
      is-scaled
    >
      <tutorial-keyboard-key
        type="space-pressed"
        letter="hold"
      />
    </tutorial-keyboard-keys>

    <tutorial-keyboard-keys
      v-if="showSharp"
      type="shift"
      :text="$t('hardCornering')"
      class="keyboard-shift"
      is-scaled
    >
      <section
        class="shift-key-wrapper"
      >
        <tutorial-keyboard-key
          type="shift"
          letter=""
        />
        <div class="plus">
          +
        </div>
        <tutorial-keyboard-key
          type="arrow"
          rotation="left"
          letter="a"
        />
        <tutorial-keyboard-key
          type="arrow"
          rotation="right"
          letter="d"
        />
      </section>
    </tutorial-keyboard-keys>
  </div>
</template>

<script lang="ts">
import {
  WindowAspect,
  TutorialKeyboardKeys,
  TutorialKeyboardKey
} from '@powerplay/core-minigames-ui'

import { defineComponent } from 'vue'
import {
  actionButtonState,
  inputsState,
  sharpTurnState,
  startPhaseState
} from '@/stores'

export default defineComponent({
  name: 'InfoKeys',
  components: {
    TutorialKeyboardKeys,
    TutorialKeyboardKey
  },
  mixins: [WindowAspect],
  computed: {
    isControlsTypeAutoTuck() {

      return true

    },
    showSpace() {

      return startPhaseState().showBar

    },
    showSpacePressed() {

      return inputsState().isVisible &&
        actionButtonState().showMovementControl &&
        !this.isControlsTypeAutoTuck &&
        !inputsState().disabled

    },
    showArrows() {

      return actionButtonState().showMovementControl &&
        !inputsState().disabled &&
        inputsState().isVisible

    },
    showSharp() {

      return actionButtonState().showMovementControl &&
        !inputsState().disabled &&
        sharpTurnState().active &&
        inputsState().isVisible

    }
  }
})
</script>

<style lang="less" scoped>
    .positioner {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 25%;

        .keyboard-side-keys {
            transform-origin: 0 100%;
            position: absolute;
            left: 2%;
            bottom: 10%;

            .tutorial-keyboard-keys-wrapper {
                height: 100px;
            }
        }

        .keyboard-space {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;
        }

        .keyboard-shift {
            transform-origin: 100% 100%;
            position: absolute;
            right: 2%;
            bottom: 10%;
        }

        .shift-key-wrapper {
            display: flex;
            align-items: center;
            gap: 20px;
            .plus {
                width: 32px;
                height: 38px;
                font-family: Roboto;
                font-size: 72px;
                font-weight: bold;
                font-stretch: condensed;
                font-style: italic;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #233441;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0 10px;
            }
        }

    }
</style>
