<template>
  <section class="positioner-buttons-movement">
    <section>
      <movement-sides
        :glow="true"
        :show-inner-buttons="tutorialState.showSharpMovement"
        :right-text="$t('utSharpRight')"
        :left-text="$t('utSharpLeft')"
      />
      <section
        class="tutorial-arrows-container"
      >
        <div
          class="arrow left-arrow"
          :style="[transformCoef, { position: 'absolute', left: 0, bottom: 0}]"
        >
          <div class="button-movement">
            <arrow-animation
              v-if="!tutorialState.showSharpMovement"
              :style="getStyleArrowAnimation"
              position="bottom"
              :is-scaled="false"
            />
          </div>
          <div class="button-sharp">
            <arrow-animation
              v-if="tutorialState.showSharpMovement"
              :style="getStyleArrowAnimation"
              position="bottom"
              :is-scaled="false"
            />
          </div>
        </div>
        <div
          class="arrow right-arrow"
          :style="[transformCoef, { position: 'absolute', right: 0, bottom: 0}]"
        >
          <div class="button-movement">
            <arrow-animation
              v-if="!tutorialState.showSharpMovement"
              :style="getStyleArrowAnimation"
              position="bottom"
              :is-scaled="false"
            />
          </div>
          <div class="button-sharp">
            <arrow-animation
              v-if="tutorialState.showSharpMovement"
              :style="getStyleArrowAnimation"
              position="bottom"
              :is-scaled="false"
            />
          </div>
        </div>
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import {
  MovementSides,
  ArrowAnimation,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { tutorialState } from '@/stores'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TutorialButtonsMovement',
  components: {
    MovementSides,
    ArrowAnimation
  },
  mixins: [WindowAspect],
  data() {

    return {
      tutorialState: tutorialState(),
    }

  },
  computed: {
    transformCoef() {

      return { transform: `scale(${this.scaleCoef})` }

    },
    getStyleArrowAnimation() {

      const style = {
        position: 'absolute',
        width: '580px',
        height: '460px',
        bottom: 0,
        left: 'unset'
      }

      if (tutorialState().showSharpMovement) {

        style.width = '190px'
        style.height = '410px'

      }

      return style

    },
  },

})
</script>

<style lang="less">
.positioner-buttons-movement {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 580px;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.tutorial-arrows-container {
    left: 0;
    height: 580px;
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;

    .arrow {
        width: 580px;
        height: 100%;
        display: flex;
        position: absolute;
        bottom: 0;

        .button-movement {
          width: 580px;
          height: 580px
        }

        .button-sharp {
          bottom: -290px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: absolute;
          width: 190px;
          height: 580px;
        }

        &.left-arrow {
          left: 0;
          transform-origin: bottom left;

          .button-sharp {
            transform: translate(-50%, -50%);
            right: unset;
            left: 580px;
          }

        }

        &.right-arrow {
          right: 0;
          transform-origin: bottom right;

          .button-sharp {
            transform: translate(50%, -50%);
            right: 580px;
            left: unset;
          }

        }

    }
}

</style>
