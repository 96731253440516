<template>
  <mobile-button
    :type="type"
    @mousedown="performAction"
    @mouseup="endAction"
    @touchstart="performAction"
    @touchend="endAction"
  />
</template>

<script lang="ts">
import { MobileButton } from '@powerplay/core-minigames-ui'
import { inputsManager } from '@powerplay/core-minigames'
import { disciplinePhasesManager } from '@/app/phases/DisciplinePhasesManager'
import { DisciplinePhases } from '@/app/types'
import type { StartPhaseManager } from '@/app/phases/StartPhaseManager'

import { defineComponent } from 'vue'
import {
  actionButtonState,
  tuckState
} from '@/stores'

export default defineComponent({
  name: 'ActionButton',
  components: {
    MobileButton
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    type() {

      return actionButtonState().isStart ? 'start-giant-slalom' : 'tuck'

    }
  },
  methods: {
    performAction() {

      if (this.disabled) return

      if (this.type === 'tuck') {

        tuckState().isTuck = true

      }

      if (this.type === 'start-giant-slalom') {

        const startPhase = disciplinePhasesManager
          .getDisciplinePhaseManager(DisciplinePhases.start) as StartPhaseManager
        if (startPhase.isAfterIntro) {

          actionButtonState().touchStart = true

        }

      }

    },
    endAction() {

      if (this.type === 'tuck') {

        tuckState().isTuck = false

      }

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    }
  }
})

</script>

<style>

</style>
