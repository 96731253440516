import {
  modes,
  type TutorialObjective
} from '@powerplay/core-minigames'
import { TutorialEventType } from '../../types'
import { tutorialFlow } from './TutorialFlow'
import { tutorialState } from '@/stores'

export class TutorialObjectives {

  private objectives: TutorialObjective[] = []

  public setObjectives(objectives: TutorialObjective[]): this {

    this.objectives = objectives
    tutorialState().objectives = this.objectives

    return this

  }

  public passObjective(objectiveId: string): void {

    if (!modes.isTutorial()) return
    const objective = this.getObjectiveById(objectiveId)
    if (!objective || objective.passed) return

    objective.failed = false
    objective.passed = true
    tutorialState().objectives = []
    tutorialState().objectives = this.objectives

  }

  public failObjective(objectiveId: string): void {

    if (!modes.isTutorial()) return
    const objective = this.getObjectiveById(objectiveId)
    if (!objective) return
    objective.passed = false
    objective.failed = true
    tutorialState().objectives = []
    tutorialState().objectives = this.objectives

  }

  public update(): void {

    if (!modes.isTutorial()) return
    this.checkIfAllObjectivesPassed()

  }

  /**
   * Zistenie, ci su vsetky ciele splnene
   * @returns True, ak su splnene vsetky ciele
   */
  public isAllObjectivesPassed(): boolean {

    let allPassed = true
    this.objectives.forEach((objective) => {

      if (!this.checkIfObjectivePassed(objective)) allPassed = false

    })

    return allPassed

  }

  /**
   * Zistenie, ci su vsetky ciele splnene
   * @returns True, ak su splnene vsetky ciele
   */
  public checkIfAllObjectivesPassed(): boolean {

    // logika
    const passedArray = this.objectives.map(this.checkIfObjectivePassed)
    if (passedArray.includes(false)) return false

    this.actionOnPassed()
    return true

  }

  private actionOnPassed(): void {

    // koniec hry
    tutorialFlow.eventActionTrigger(TutorialEventType.finishEvent)

  }

  public checkIfObjectivePassed(objective?: TutorialObjective): boolean {

    return objective !== undefined && objective.passed

  }

  public getObjectiveById(id: string): TutorialObjective | undefined {

    const objectiveIndex = this.objectives.findIndex((objective: TutorialObjective) => {

      return objective.id === id

    })

    if (objectiveIndex === -1) return undefined

    return this.objectives[objectiveIndex]

  }

}
export const tutorialObjectives = new TutorialObjectives()
