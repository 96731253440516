import {
  CANNON,
  THREE,
  playersManager
} from '@powerplay/core-minigames'
import {
  gameConfig,
  unitCyclesConfig
} from '../config'
import type { VectorXZ } from '../types'
import { PlayerInputsUnitCycleManager } from './PlayerInputsUnitCycleManager'
import { player } from '.'
import { inputsManager } from '../InputsManager'
import {
  directionsState,
  mainState,
  sharpTurnState,
  tuckState
} from '@/stores'


/**
 * Trieda pre spravu rychlosti hraca
 */
export class PlayerVelocityManager {

  /** Nova rychlost */
  public velocity = new CANNON.Vec3()

  /** Objekt jednotkovej kruznice pre playerove inputy */
  private playerInputsUnitCycle = new PlayerInputsUnitCycleManager()

  /** Posledna hracova rotacia na osi y */
  private lastPlayerRotationY = 0

  /** Pomocny objekt na vypocty */
  private tempObject = new THREE.Object3D()

  /** Pomocny vektor */
  private tempVector = new THREE.Vector3()

  /** Aktualna max rychlost lyziara */
  private maxSpeed = 0

  /**
   * Ziskanie rychlosti
   * @returns Rychlost
   */
  public getVelocity(): CANNON.Vec3 {

    return this.velocity

  }

  /**
   * Aktualizovanie veci podla konfigu od GD
   */
  public setAttributesGD(): void {

    this.maxSpeed = gameConfig.maxSpeedNormal
    this.playerInputsUnitCycle.setAddCoefByAttribute(playersManager.getPlayer().attribute.total)

  }

  /**
   * Ziskanie rotacie na osi y z rychlosti
   * @param velocity - Vektor rychlosti
   * @returns Y rotacia
   */
  public getRotationYFromVelocity(velocity: CANNON.Vec3): number {

    this.tempVector.set(
      velocity.x,
      0,
      velocity.z
    )

    this.tempObject.position.set(0, 0, 0)
    this.tempObject.lookAt(this.tempVector)

    /**
     * DOLEZITE - lookAt dava iba rotaciu do Math.PI
     *
     * Ked davame nastavovanie rotacie podla lookAt, tak zaciname pohladom na zaporne Z, kde by
     * bola rotacia 0, potom po smere hodinovych ruciciek a po 45 stupnoch by to islo takto:
     * 0 .. PI/4 .. PI/2 .. PI/4 .. 0 .. -PI/4 .. -PI/2 .. -PI/4 .. 0
     * preto musime upravit danu rotaciu az pri vypocte, lebo keby sme ju upravili na objekte,
     * tak by sa zmenila znova na mensie
     */
    if (velocity.z > 0) {

      const sign = velocity.x >= 0 ? 1 : -1
      this.tempObject.rotation.y = (sign * Math.PI) - this.tempObject.rotation.y

    }

    return this.tempObject.rotation.y

  }

  /**
   * Vypocitanie interpolacie medzi dvomi rotaciami podla percenta
   * @param rotationYNew - Nova rotacia
   * @param rotationYLast - Posledna rotacia
   * @param percent - Percento (1 - pri new, 0 - pri last)
   * @returns Interpolovana rotacia
   */
  private getInterpolationOfNewAndLastRotationY(
    rotationYNew: number,
    rotationYLast: number,
    percent: number
  ): number {

    // ked je nova rotacia mensia, tak je iny vzorec ako opacne
    if (rotationYNew < rotationYLast) {

      return rotationYLast - (Math.abs(rotationYLast - rotationYNew) * percent)

    }

    return rotationYLast + (Math.abs(rotationYNew - rotationYLast) * percent)

  }

  /**
   * Vypocitanie sily, ktora bude pohanat lyziara dopredu
   * @param velocity - Rychlost
   * @returns Vektor na osiach X a Z
   */
  private getForceForward(velocity: CANNON.Vec3): VectorXZ {

    // urcime si zaklad podla atributu
    const playerStrength = playersManager.getPlayer().attribute.total

    let addCoef = 0.02
    let subCoef = 0
    let divCoef = 100
    let multiplyCoef = 0.01

    if (playerStrength > 1000) {

      addCoef = 0.07
      subCoef = 1000
      divCoef = 1000
      multiplyCoef = 0.01

    } else if (playerStrength > 100) {

      addCoef = 0.03
      subCoef = 100
      divCoef = 900
      multiplyCoef = 0.04

    }

    const forwardForce = addCoef + (((playerStrength - subCoef) / divCoef) * multiplyCoef)
    const velocitySum: number = Math.abs(velocity.x) + Math.abs(velocity.z)

    // vypocet je jednoduchy, pomerovo si urcime, kolko z koeficientu dame na danu os
    return {
      x: forwardForce * (velocity.x / velocitySum),
      z: forwardForce * (velocity.z / velocitySum)
    }

  }

  /**
   * Auto tuck
   */
  private autoTuck(): void {

    if (gameConfig.autoTuckDisabled) return

    const playerInputsUnitCyclePercentage = this.playerInputsUnitCycle.calculatePercentage()
    const isTuck = Math.abs(playerInputsUnitCyclePercentage) <= gameConfig.autoTuckLimit

    tuckState().isTuck = isTuck

  }

  /**
   * Ziskanie aktualizovanej rotacie na zaklade hracovych inputov
   * @param velocity - Rychlost
   * @param percentInputs - percento hracovych inputov
   * @returns Rotacia
   */
  private getUpdatedRotationYByPlayerInputs(
    velocity: CANNON.Vec3,
    percentInputs: number
  ): number {

    // zistime spravnu rotaciu, ktora bude podla konfigu vela alebo malo ovplyvnovana fyzikou
    const interpolatedRotation: number = this.getInterpolationOfNewAndLastRotationY(
      this.getRotationYFromVelocity(velocity),
      this.lastPlayerRotationY,
      gameConfig.percentPhysicsRotationChange
    )

    let isSharpTurn = inputsManager.actionPressed2 || sharpTurnState().isSharpTurn
    if (!sharpTurnState().active) {

      isSharpTurn = false

    }

    const coefChange = isSharpTurn ?
      unitCyclesConfig.playerInputs.coefDirectionChangeSharp :
      unitCyclesConfig.playerInputs.coefDirectionChange

    // prirastok urcime podla toho, ako vela mame inputy nastavene
    const rotationAdd: number = percentInputs * coefChange

    return interpolatedRotation + rotationAdd

  }

  /**
   * Ziskanie hodnoty, ktorou sa nasobi rychlost, aby sme lyziara spomalovali
   * @param percentInputs - percento hracovych inputov
   * @param coef - koeficient
   * @returns Koeficient spomalovania
   */
  private getVelocityDecreaseValue(percentInputs: number, coef: number): number {

    return (1 - (Math.abs(percentInputs) * coef))

  }

  /**
   * Vypocitanie zmenenej hodnoty rychlosti po pridani force forward a spomaleni
   * @param velocity - rychlost
   * @param percentInputs - percento hracovych inputov
   * @returns Rychlost
   */
  private getChangedVelocity(velocity: CANNON.Vec3, percentInputs: number): CANNON.Vec3 {

    // forward force - pohyb dopredu
    const forceForward: VectorXZ = this.getForceForward(velocity)

    const coefTurnSlowing = 0.024 - playersManager.getPlayer().attribute.total / 2000 * 0.008

    // ubytok vo velocity podla inputov
    const velocityDecreaseValue: number = this.getVelocityDecreaseValue(
      percentInputs,
      coefTurnSlowing
    )

    return new CANNON.Vec3(
      (velocity.x + forceForward.x) * velocityDecreaseValue,
      velocity.y * velocityDecreaseValue,
      (velocity.z + forceForward.z) * velocityDecreaseValue
    )

  }

  /**
   * Ziskanie prepony rychlosti na osiach X a Z
   * @param velocityX - rychlost na X
   * @param velocityZ - rychlost na Z
   * @returns Prepona
   */
  private getVelocityHypotenuseXZ(velocityX: number, velocityZ: number): number {

    return Math.sqrt((velocityX ** 2) + (velocityZ ** 2))

  }

  /**
   * Redistribuovanie rychlosti na XZ
   * @param hypotenuseVelocityXZ - prepona rychlosti na XZ
   * @param rotationY - rotacia na osi Y
   * @returns Nova rychlost na XZ
   */
  private redistributeVelocityXZ(hypotenuseVelocityXZ: number, rotationY: number): VectorXZ {

    // pri rotaciach vacsich ako PI / 2 musime pocitat inak, lebo pocitame opacny trojuholnik
    if (rotationY > Math.PI / 2) {

      const newRotationChanged = rotationY - (Math.PI / 2)

      return {
        x: hypotenuseVelocityXZ * Math.cos(newRotationChanged),
        z: hypotenuseVelocityXZ * Math.sin(newRotationChanged) * -1
      }

    }

    return {
      x: hypotenuseVelocityXZ * Math.sin(rotationY),
      z: hypotenuseVelocityXZ * Math.cos(rotationY)
    }

  }

  /**
   * Nastavenie novej velocity podla roznych veci
   */
  public getNewVelocity(velocity: CANNON.Vec3, inAir = false): CANNON.Vec3 {

    // zatacanie vlavo / vpravo - nastavime parametre na jednotkovej kruznici pre hracov pohyb
    this.playerInputsUnitCycle.update(inAir)
    const playerInputsUnitCyclePercentage = this.playerInputsUnitCycle.calculatePercentage()

    const velocityChanged: CANNON.Vec3 = this.getChangedVelocity(
      velocity,
      playerInputsUnitCyclePercentage
    )

    // vypocitame si rychlost na 2 osiach, aby sme vedeli spravit distribuciu podla posunu
    const velocityHypotenuseXZ: number = this.getVelocityHypotenuseXZ(
      velocityChanged.x,
      velocityChanged.z
    )

    // vypocitame novu rotaciu na zaklade hracovych inputov
    const newRotation: number = this.getUpdatedRotationYByPlayerInputs(
      velocity,
      playerInputsUnitCyclePercentage
    )

    // redistribuujeme rychlost na XZ podla noveho uhla
    const newVelocityXZ: VectorXZ = this.redistributeVelocityXZ(
      velocityHypotenuseXZ,
      newRotation
    )

    this.velocity.set(
      newVelocityXZ.x,
      velocityChanged.y,
      newVelocityXZ.z * -1
    )

    return this.velocity

  }

  /**
   * Nastavenie poslednej rotacie z aktualnej rychlosti na vypocty v dalsom cykle
   * @param velocityOriginal - rychlost
   * @returns Specialna rotacia
   */
  public setLastRotationFromVelocity(velocityOriginal: CANNON.Vec3): number {

    let velocity = velocityOriginal.clone()

    // na zaciatku musime dat manualne pozeranie sa dopredu, lebo ziadna velocity este nie je
    if (velocity.x === 0 && velocity.y === 0 && velocity.z === 0) {

      velocity = gameConfig.startVelocityDirection

    }

    this.lastPlayerRotationY = this.getRotationYFromVelocity(velocity)

    // UI update
    directionsState().player = THREE.MathUtils.radToDeg(this.lastPlayerRotationY)

    return this.lastPlayerRotationY

  }

  /**
   * Pohyb lyziara
   */
  private move(inAir = false): void {

    this.autoTuck()

    if (tuckState().isTuck) {

      player.isCrouching = true
      if (this.maxSpeed < gameConfig.maxSpeedCrouch) this.maxSpeed += 0.2

    } else {

      player.isCrouching = false
      if (this.maxSpeed > gameConfig.maxSpeedNormal) this.maxSpeed -= 0.1

    }

    // vypocitame novu rychlost
    player.physicsBody.velocity = this.getNewVelocity(player.physicsBody.velocity, inAir)

  }

  /**
   * Skontrolovanie min a max rychlosti lyziara
   */
  private checkMinMaxSpeed(): void {

    const { velocity } = player.physicsBody

    let velocitySum = Math.abs(velocity.x) + Math.abs(velocity.z)
    const xPercent = 100 / velocitySum * Math.abs(velocity.x)
    const zPercent = 100 / velocitySum * Math.abs(velocity.z)

    if (velocitySum > this.maxSpeed) {

      velocitySum = this.maxSpeed
      player.physicsBody.velocity.x = velocitySum / 100 * xPercent * Math.sign(velocity.x)
      player.physicsBody.velocity.z = velocitySum / 100 * zPercent * Math.sign(velocity.z)

    }

  }

  /**
   * Aktualizovanie rychlosti kratko po starte
   */
  private updateStartingVelocity(): void {

    const { startSpeedUpCoef } = gameConfig

    const velocity = new CANNON.Vec3(
      player.physicsBody.velocity.x,
      player.physicsBody.velocity.y,
      player.physicsBody.velocity.z - startSpeedUpCoef
    )

    player.physicsBody.velocity = this.getNewVelocity(velocity)

  }

  /**
   * Aktualizovanie rychlosti
   */
  public update(inAir = false): void {

    // rychlost upravujeme iba vtedy, ked nie je hrac vo vzduchu a ma aktivne pohybove animacie
    if (player.activeUpdatingMovementAnimations && !player.jumpStates.inAir) {

      this.move(inAir)
      this.checkMinMaxSpeed()

    }

    // rychlost upravujeme specialne aj na zaciatku, kedy este nie su aktivne pohybove animacie
    if (player.isSkating) this.updateStartingVelocity()

    // UI update
    mainState().$patch({
      maxSpeed: this.maxSpeed,
      velocityX: player.physicsBody.velocity.x,
      velocityY: player.physicsBody.velocity.y,
      velocityZ: player.physicsBody.velocity.z
    })

  }

  /**
   * Resetovanie
   */
  public reset(): void {

    this.velocity = new CANNON.Vec3()
    this.playerInputsUnitCycle.actualValue = 0

  }

}
