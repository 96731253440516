<template>
  <section class="positioner">
    <section
      v-if="inputsState.isVisible && actionButtonState.showMovementControl && isControlsTypeAutoTuck"
    >
      <movement-sides
        :key="sharpTurnState.active"
        :disabled="inputsState.disabled"
        :show-inner-buttons="sharpTurnState.active"
        :right-text="$t('utSharpRight')"
        :left-text="$t('utSharpLeft')"
        @position="changeMovement"
        @end-contact="endContact"
        @position-inner="changeMovementSharp"
        @end-contact-inner="endContactSharp"
      />
    </section>
    <section
      v-if="inputsState.isVisible && !(isControlsTypeAutoTuck && actionButtonState.showMovementControl)"
    >
      <section
        v-if="!gameSettingsState.isLeft"
        class="flex justify-between right"
        :style="`transform: scale(${scaleCoef})`"
      >
        <section v-show="!actionButtonState.showMovementControl" />
        <section
          v-if="startPhaseState.showBar"
          class="relative"
        >
          <action-button
            :disabled="inputsState.disabled"
            :is-scaled="false"
          />
          <start-efficiency-bar
            :is-scaled="false"
          />
        </section>
      </section>
      <section
        v-else
        class="flex justify-between left"
        :style="`transform: scale(${scaleCoef})`"
      >
        <section
          v-if="startPhaseState.showBar"
          class="relative"
        >
          <start-efficiency-bar
            :is-scaled="false"
            :is-left="gameSettingsState.isLeft"
          />
          <action-button
            :disabled="inputsState.disabled"
            :is-scaled="false"
          />
        </section>
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import ActionButton from './ActionButton.vue'
import StartEfficiencyBar from './StartEfficiencyBar.vue'
import {
  gameSettingsState,
  MovementSides,
  WindowAspect
} from '@powerplay/core-minigames-ui'
import { inputsManager } from '@powerplay/core-minigames'
import { tutorialObjectives } from '@/app/modes/tutorial/TutorialObjectives'
import { TutorialObjectiveIds } from '@/app/types'
import { tutorialFlow } from '@/app/modes/tutorial/TutorialFlow'

import { defineComponent } from 'vue'
import {
  actionButtonState,
  inputsState,
  movementState,
  sharpTurnState,
  startPhaseState
} from '@/stores'

export default defineComponent({
  name: 'MobileInputs',
  components: {
    StartEfficiencyBar,
    ActionButton,
    MovementSides
  },
  mixins: [WindowAspect],
  data() {

    return {
      actionButtonState: actionButtonState(),
      inputsState: inputsState(),
      sharpTurnState: sharpTurnState(),
      startPhaseState: startPhaseState(),
      gameSettingsState: gameSettingsState(),
    }

  },
  computed: {
    isControlsTypeAutoTuck() {

      return true

    }
  },
  methods: {
    endContact() {

      movementState().positionX = 0

      // musime dat manulane ukoncenie buttonu, aby sa ukoncil takisto actionPressed v inputs
      inputsManager.handleMouseUp()

    },
    changeMovement(positionX: number) {

      if (this.disabled) return

      movementState().positionX = positionX

    },
    changeMovementSharp(positionX: number) {

      if (this.disabled) return

      sharpTurnState().isSharpTurn = true
      this.changeMovement(positionX)
      tutorialObjectives.passObjective(TutorialObjectiveIds.sharpTurn)
      tutorialFlow.sharpTurnUsed = true

    },
    endContactSharp() {

      sharpTurnState().isSharpTurn = false
      this.endContact()

    }
  }
})

</script>

<style lang="less" scoped>
.positioner {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.relative {
  position: relative;
}

.left {
  transform-origin: bottom left;
}

.right {
  transform-origin: bottom right;
}
</style>
