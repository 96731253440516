import {
  audioManager,
  modes,
  THREE
} from '@powerplay/core-minigames'
import { unitCyclesConfig } from '../config/unitCyclesConfig'
import { player } from '.'
import { inputsManager } from '../InputsManager'
import { gameConfig } from '../config'
import {
  AudioNames,
  TutorialObjectiveIds
} from '../types'
import { tutorialObjectives } from '../modes/tutorial/TutorialObjectives'
import { tutorialFlow } from '../modes/tutorial/TutorialFlow'
import {
  directionsState,
  movementState,
  sharpTurnState
} from '@/stores'

/**
 * Trieda pre spravu jednotkovej kruznice pre hracove inputy
 */
export class PlayerInputsUnitCycleManager {

  /** Aktualna hodnota na jednotkovej kruznici */
  #actualValue = 0

  /** true ak uz bola zacata prva sharpTurn */
  private firstSharpTurn = false

  private coefAdd = {
    normal: 0,
    sharp: 0
  }

  /**
   * getter
   */
  public get actualValue(): number {

    return this.#actualValue

  }

  /**
   * setter
   */
  public set actualValue(value: number) {

    this.#actualValue = value

  }

  /**
   * Nastavenie add koeficientu podla atributu
   * @param playerStrength - Sila hraca
   */
  public setAddCoefByAttribute(playerStrength: number) {

    const { sharp, normal } = unitCyclesConfig.playerInputs.coefAdd

    this.coefAdd.normal = normal + playerStrength / 2000 * 0.03
    this.coefAdd.sharp = sharp + playerStrength / 2000 * 0.03

  }

  /**
   * Posunutie aktualnej hodnoty po jednotkovej kruznici proti smeru hodinovych ruciciek
   */
  public toLeftSide(positionX: number): void {

    if (!player.activeUpdatingMovementAnimations) return

    const maxAngleFast = unitCyclesConfig.playerInputs.maxAngleFast

    let isSharpTurn = inputsManager.actionPressed2 || sharpTurnState().isSharpTurn

    if (!sharpTurnState().active) {

      isSharpTurn = false

    } else if (isSharpTurn) {

      this.firstSharpTurn = true
      tutorialFlow.sharpTurnUsed = true

    }

    let maxAngle = isSharpTurn ?
      unitCyclesConfig.playerInputs.maxAngleSharp :
      unitCyclesConfig.playerInputs.maxAngleNormal

    if (positionX) maxAngle *= Math.abs(positionX)

    // Ak je to mozne, tak urychlime proces posuvania
    if (this.actualValue > maxAngleFast) this.actualValue = maxAngleFast

    const coefAdd = isSharpTurn ?
      this.coefAdd.sharp :
      this.coefAdd.normal

    this.actualValue -= coefAdd

    // menej ako min uz nedavame
    if (this.actualValue < -maxAngle) this.actualValue = -maxAngle

  }

  /**
   * Posunutie aktualnej hodnoty po jednotkovej kruznici v smere hodinovych ruciciek
   */
  public toRightSide(positionX: number): void {

    if (!player.activeUpdatingMovementAnimations) return

    const maxAngleFast = unitCyclesConfig.playerInputs.maxAngleFast

    let isSharpTurn = inputsManager.actionPressed2 || sharpTurnState().isSharpTurn

    if (!sharpTurnState().active) {

      isSharpTurn = false

    } else if (isSharpTurn) {

      this.firstSharpTurn = true
      tutorialFlow.sharpTurnUsed = true

    }

    let maxAngle = isSharpTurn ?
      unitCyclesConfig.playerInputs.maxAngleSharp :
      unitCyclesConfig.playerInputs.maxAngleNormal

    if (positionX) maxAngle *= Math.abs(positionX)

    // Ak je to mozne, tak urychlime proces posuvania
    if (this.actualValue < -maxAngleFast) this.actualValue = -maxAngleFast

    const coefAdd = isSharpTurn ?
      this.coefAdd.sharp :
      this.coefAdd.normal

    this.actualValue += coefAdd

    // viac ako po max uz nedavame
    if (this.actualValue > maxAngle) this.actualValue = maxAngle

  }

  /**
   * vyhodnotenie sharpTurn tutorialObjective podmienok
   * @param isSharpTurn - sharpTurn aktivna
   */
  public tutorialSharpTurn(isSharpTurn: boolean): void {

    if (!isSharpTurn && this.firstSharpTurn) {

      tutorialObjectives.passObjective(TutorialObjectiveIds.sharpTurn)

    }

  }

  /**
   * Posunutie aktualnej hodnoty po jednotkovej kruznici v pripade ak sa netlaci tlacidlo
   */
  public pivotToCenter(): void {

    const pivotCoef = unitCyclesConfig.playerInputs.coefReturnToCenter

    if (this.actualValue > 0) {

      this.actualValue -= pivotCoef

      if (this.actualValue < 0) this.actualValue = 0

    } else {

      this.actualValue += pivotCoef

      if (this.actualValue > 0) this.actualValue = 0

    }

  }

  /**
   * Aktualizovanie jednotkovej kruznice
   */
  public update(inAir = false): void {

    if (
      !inAir && (
        inputsManager.moveDirectionLeft || movementState().positionX < 0
      )
    ) {

      this.toLeftSide(movementState().positionX)

    } else if (
      !inAir && (inputsManager.moveDirectionRight || movementState().positionX > 0)
    ) {

      this.toRightSide(movementState().positionX)

    } else {

      this.pivotToCenter()

    }

    if (Math.abs(this.actualValue) >= gameConfig.soundOnTurn) {

      audioManager.play(AudioNames.skiingCorner)

    } else {

      audioManager.stopAudioByName(AudioNames.skiingCorner)

    }

    // UI update
    directionsState().movement = THREE.MathUtils.radToDeg(this.actualValue)

    const isSharpTurn = inputsManager.actionPressed2 || sharpTurnState().isSharpTurn

    if (!modes.isTutorial()) return
    this.tutorialSharpTurn(Boolean(isSharpTurn))

  }

  /**
   * Vypocitanie percent aktualnej hodnoty na jednotkovej kruznici
   * @returns Hodnota v %
   */
  public calculatePercentage(): number {

    return this.actualValue / unitCyclesConfig.playerInputs.maxAngleSharp

  }

}
