import { defineStore } from 'pinia'

export interface PlayerState {
  maxSpeed: number,
  velocityX: number,
  velocityY: number,
  velocityZ: number,
}

const initialState = (): PlayerState => ({
  maxSpeed: 1,
  velocityX: 0,
  velocityY: 0,
  velocityZ: 0
})

export const mainState = defineStore('mainState', {
  state: initialState,
})
/*
const mainState: Module<PlayerState, RootState> = {
  state: initialState(),

  namespaced: true,

  getters: {
    getMainState: (moduleState: PlayerState) => moduleState
  },

  mutations: {
    RESET: (moduleState: PlayerState) => {

      Object.assign(moduleState, initialState())

    },
    SET_STATE: (moduleState: PlayerState, newState: PlayerState) => {

      moduleState.maxSpeed = newState.maxSpeed
      moduleState.velocityX = newState.velocityX
      moduleState.velocityY = newState.velocityY
      moduleState.velocityZ = newState.velocityZ

    }
  }
}

export default mainState
*/