import { inputsManager } from '../InputsManager'
import {
  PlayerAnimationsNames,
  type PlayerMovementAnimationsWeightsSetup,
  Sides
} from '../types'
import { player } from './Player'
import { gameConfig } from '../config'
import { movementState } from '@/stores'

/**
 * Trieda pre spravu vah hracovych animacii
 */
export class PlayerMovementAnimationWeightManager {

  /** setup vah pre pohybove animacie */
  private setup: PlayerMovementAnimationsWeightsSetup = {
    crouchCenter: { animation: PlayerAnimationsNames.tuck,
      weight: 0,
      value: 0 },
    crouchLeft: { animation: PlayerAnimationsNames.tuckTurnLeft,
      weight: 0,
      value: 0 },
    crouchRight: { animation: PlayerAnimationsNames.tuckTurnRight,
      weight: 0,
      value: 0 },
    standCenter: { animation: PlayerAnimationsNames.carve,
      weight: 0,
      value: 0 },
    standLeft: { animation: PlayerAnimationsNames.carveLeft,
      weight: 0,
      value: 0 },
    standRight: { animation: PlayerAnimationsNames.carveRight,
      weight: 0,
      value: 0 }
  }

  /** Limit pre nahybanie sa, ak uz som dlho na jednej strane, tak uz by som mal mat 100% */
  private TURN_LIMIT = 5

  /**
   * getter movementAnimationsWeightSetup
   * @returns MovementAnimationsWeightsSetup
   */
  public getAnimationWeightData(): PlayerMovementAnimationsWeightsSetup {

    return this.setup

  }

  /**
   * getter TURN_LIMIT
   * @returns TurnLimit
   */
  public getTurnLimit(): number {

    return this.TURN_LIMIT

  }

  /**
   * update pohyb vlavo
   * @param crouch - skrcenie
   * @param value - hodnota na zmenu
   */
  public updateMovementWeightLeft(crouch: boolean, value = 1): void {

    const localValue = parseFloat(value.toFixed(2))
    const { TURN_LIMIT } = this
    const maxTurn = TURN_LIMIT * localValue
    const turnSpeed = gameConfig.animationTurningSpeedCoef

    if (crouch) {

      if (this.setup.crouchLeft.value >= maxTurn) {

        this.setup.crouchLeft.value = maxTurn

      } else {

        this.setup.crouchLeft.value += turnSpeed

      }

      this.setup.standLeft.value -= turnSpeed

    } else {

      if (this.setup.standLeft.value >= maxTurn) {

        this.setup.standLeft.value = maxTurn

      } else {

        this.setup.standLeft.value += turnSpeed

      }
      this.setup.crouchLeft.value -= turnSpeed

    }

    this.setup.standRight.value -= turnSpeed
    this.setup.crouchRight.value -= turnSpeed

  }

  /**
   * update pohyb vpravo
   * @param crouch - skrcenie
   * @param value - hodnota na zmenu
   */
  public updateMovementWeightRight(crouch: boolean, value = 1): void {

    const localValue = parseFloat(value.toFixed(2))
    const { TURN_LIMIT } = this
    const maxTurn = TURN_LIMIT * localValue
    const turnSpeed = gameConfig.animationTurningSpeedCoef

    if (crouch) {

      if (this.setup.crouchRight.value >= maxTurn) {

        this.setup.crouchRight.value = maxTurn

      } else {

        this.setup.crouchRight.value += turnSpeed

      }

      this.setup.standRight.value -= turnSpeed

    } else {

      if (this.setup.standRight.value >= maxTurn) {

        this.setup.standRight.value = maxTurn

      } else {

        this.setup.standRight.value += turnSpeed

      }

      this.setup.crouchRight.value -= turnSpeed

    }

    this.setup.standLeft.value -= turnSpeed
    this.setup.crouchLeft.value -= turnSpeed

  }

  /**
   * update pri rovnom pohybe
   */
  public updateMovementWeightStraight(): void {

    const turnSpeed = gameConfig.animationTurningSpeedCoef
    this.setup.standLeft.value -= turnSpeed
    this.setup.crouchLeft.value -= turnSpeed
    this.setup.standRight.value -= turnSpeed
    this.setup.crouchRight.value -= turnSpeed

  }

  /**
   * zmena vahy pri krceni
   * @param crouch - skrceny
   * @param value - hodnota na zmenu
   */
  public updateCrouchWeight(crouch: boolean, value = 1): void {

    if (crouch) {

      this.setup.crouchCenter.value += value

    } else {

      this.setup.crouchCenter.value -= value

    }

  }

  /**
   * manage min
   */
  public checkMinWeights(): void {

    const {
      standLeft, standRight, crouchLeft, crouchRight
    } = this.setup

    if (standLeft.value < 0) this.setup.standLeft.value = 0
    if (crouchLeft.value < 0) this.setup.crouchLeft.value = 0
    if (standRight.value < 0) this.setup.standRight.value = 0
    if (crouchRight.value < 0) this.setup.crouchRight.value = 0

  }

  /**
   * manage max
   */
  public checkMaxWeights(side: Sides): void {

    const {
      standLeft, standRight, crouchLeft, crouchRight
    } = this.setup

    const { TURN_LIMIT } = this

    if (side === Sides.LEFT) {

      if (standLeft.value > TURN_LIMIT) this.setup.standLeft.value = TURN_LIMIT
      if (crouchLeft.value > TURN_LIMIT) this.setup.crouchLeft.value = TURN_LIMIT

    }

    if (side === Sides.RIGHT) {

      if (standRight.value > TURN_LIMIT) this.setup.standRight.value = TURN_LIMIT
      if (crouchRight.value > TURN_LIMIT) this.setup.crouchRight.value = TURN_LIMIT

    }

  }

  /**
   * manage min max skrcenie
   */
  public checkMinMaxCrouch(): void {

    const { crouchCenter } = this.setup
    const { TURN_LIMIT } = this

    if (crouchCenter.value > TURN_LIMIT) this.setup.crouchCenter.value = TURN_LIMIT
    if (crouchCenter.value < 0) this.setup.crouchCenter.value = 0

  }

  private isNavigatingLeft(): boolean {

    return !!inputsManager.moveDirectionLeft || movementState().positionX < 0

  }

  private isNavigatingRight(): boolean {

    return !!inputsManager.moveDirectionRight || movementState().positionX > 0

  }

  private isGoingStraight(): boolean {

    return inputsManager.moveDirectionRight +
      inputsManager.moveDirectionLeft +
      movementState().positionX === 0

  }

  /**
   * Aplikovanie vah na animacie podla inputov
   * @param isCrouching - ci hrac cupi
   */
  public applyWeights(isCrouching: boolean): void {

    const {
      standLeft, standRight, crouchLeft, crouchRight, crouchCenter
    } = this.setup

    const { TURN_LIMIT } = this

    const absoluteSide = Math.abs(movementState().positionX)

    // LAVA STRANA
    if (this.isNavigatingLeft()) {

      if (absoluteSide === 0) {

        this.updateMovementWeightLeft(isCrouching)

      } else {

        this.updateMovementWeightLeft(isCrouching, absoluteSide)

      }

      this.checkMaxWeights(Sides.LEFT)

    }

    // PRAVA STRANA
    if (this.isNavigatingRight()) {

      if (absoluteSide === 0) {

        this.updateMovementWeightRight(isCrouching)

      } else {

        this.updateMovementWeightRight(isCrouching, absoluteSide)

      }

      this.checkMaxWeights(Sides.RIGHT)

    }

    // rovno
    if (this.isGoingStraight()) {

      this.updateMovementWeightStraight()

    }

    this.checkMinWeights()

    this.setup.standLeft.weight = standLeft.value / TURN_LIMIT
    this.setup.standRight.weight = standRight.value / TURN_LIMIT
    this.setup.standCenter.weight = 1 - (crouchCenter.value / TURN_LIMIT)
    this.setup.crouchLeft.weight = crouchLeft.value / TURN_LIMIT
    this.setup.crouchRight.weight = crouchRight.value / TURN_LIMIT
    this.setup.crouchCenter.weight = (crouchCenter.value / TURN_LIMIT)

    this.updateCrouchWeight(isCrouching)
    this.checkMinMaxCrouch()

    if (isCrouching) {

      this.setup.crouchCenter.weight = 1 - Math.max(crouchLeft.weight, crouchRight.weight)

    } else {

      this.setup.standCenter.weight = 1 - Math.max(standLeft.weight, standRight.weight)

    }

    for (const value of Object.values(this.setup)) {

      player.animationsManager.setWeight(value.animation, value.weight)

    }

  }

  /**
   * Aplikovanie nulovej vahy pre vsetky veci zo setupu
   */
  public applyZeroWeightToAll(): void {

    for (const value of Object.values(this.setup)) {

      if (value.animation !== PlayerAnimationsNames.carve) {

        player.animationsManager.setWeight(value.animation, 0)

      }

    }

  }

}
