<template>
  <div
    :class="isMobile() ? 'positioner' : 'nomobile-positioner'"
    :style="basicStyle"
  >
    <start-bar
      :is-scaled="isScaled"
      :fill-size="forcedSize > 0 ? forcedSize : Number(timer)"
      :glow="glow"
    />
  </div>
</template>

<script lang="ts">
import {
  WindowAspect,
  StartBar
} from '@powerplay/core-minigames-ui'
import {
  startPhaseState,
  type StartPhaseState
} from '@/stores'

import { defineComponent } from 'vue'

export default defineComponent({
  name: 'StartEfficiencyBar',
  components: {
    StartBar
  },
  mixins: [WindowAspect],
  props: {
    isLeft: {
      type: Boolean,
      default: false
    },
    forcedSize: {
      type: Number,
      default: -1
    },
    glow: {
      type: Boolean,
      default: false
    },
    isScaled: {
      type: Boolean,
      default: true
    }
  },
  data() {

    return {
      timer: 0,
      startPhaseState: startPhaseState(),
    }

  },
  computed: {
    basicStyle() {

      if (!this.isLeft) {

        return {
          'justify-content': 'flex-start'
        }

      }

      return {}

    }
  },
  watch: {

    startPhaseState: {
      immediate: true,
      deep: true,
      handler(value: StartPhaseState) {

        const newValue = Number(value?.soundTimer.toFixed(2))
        if (newValue !== this.timer) {

          this.timer = newValue

        }

      }
    }
  }
})

</script>
<style scoped lang="less">
.positioner {
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    pointer-events: none;
}
.nomobile-positioner {
    pointer-events: none;
    position: absolute;
    left: 5%;
    bottom: 5%;
    .power-start-bar {
        transform-origin: center bottom;
    }

}
</style>
