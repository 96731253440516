<template>
  <div class="positioner-middle">
    <shiny-text-animated
      v-for="text in shinyTextsState.texts"
      :key="text.name"
      :active="text.active"
      :type="text.name"
      is-scaled
    />
  </div>
</template>

<script lang="ts">
import { ShinyTextAnimated } from '@powerplay/core-minigames-ui'

import { defineComponent } from 'vue'
import { shinyTextsState } from '@/stores'

export default defineComponent({
  name: 'ShinyTextAnimatedComponent',
  components: {
    ShinyTextAnimated
  },
  data() {

    return {
      shinyTextsState: shinyTextsState(),
    }

  }
})

</script>

<style lang="less" scoped>
.positioner-middle {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .shiny-text-animated-container {
        position: absolute;
    }
}
</style>
