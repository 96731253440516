import {
  audioManager,
  cameraManager
} from '@powerplay/core-minigames'
import { cameraConfig } from '../config'
import {
  AudioNames,
  type DisciplinePhaseManager
} from '../types'
import {
  actionButtonState,
  inputsState
} from '@/stores'

/**
 * Trieda fazy pre zjazd
 */
export class GamePhaseManager implements DisciplinePhaseManager {

  /** callback na zavolanie po skonceni fazy */
  public callbackEnd: () => unknown

  /**
   * Konstruktor
   */
  public constructor(callbackEnd: () => unknown) {

    this.callbackEnd = callbackEnd

  }

  /**
   * Pripravenie fazy
   */
  public preparePhase = (): void => {

    // zatial netreba nic

  }

  /**
   * Start fazy
   */
  public startPhase = (): void => {

    console.warn('game phase started')

    this.showMobileButtons()
    audioManager.play(AudioNames.voiceStart)
    cameraManager.setTLookAtCoef(cameraConfig.tLookAtCoef)

  }

  public showMobileButtons(): void {

    actionButtonState().$patch({
      showMovementControl: true,
      isStart: false
    })
    inputsState().disabled = true

  }

  /**
   * Aktualizovanie fazy
   */
  public update = (): void => {

    // zatial netreba nic

  }

  /**
   * Ukoncene fazy
   * @param type - Typ ukoncenia
   */
  public finishPhase = (): void => {

    console.warn('game phase ended')
    this.callbackEnd()
    cameraManager.setTLookAtCoef(undefined)

  }

  /**
   * sets finish phase tween
   */
  public setFinishPhaseTween(): void {

    //

  }

}
