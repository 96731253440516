import { defineStore } from 'pinia'

export interface SharpTurnState {
  isSharpTurn: boolean,
  active: boolean
}

const initialState = (): SharpTurnState => ({
  isSharpTurn: false,
  active: false
})

export const sharpTurnState = defineStore('sharpTurnState', {
  state: initialState,
})
