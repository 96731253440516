import {
  audioManager,
  modes,
  timeManager,
  gsap,
  minigameConfig
} from '@powerplay/core-minigames'
import { gatesConfig } from './config'
import {
  AudioGroups,
  AudioNames,
  type SplitInfo
} from './types'
import {
  splitTimeState,
  timeState
} from '@/stores'

/**
 * Trieda pre spravu medzicasov
 */
export class SplitTimeManager {

  /** Indexy branok, kde budu medzicasy */
  private splitInfo: SplitInfo[] = []

  /** Pocet medzicasov */
  private splitCount = 0

  /** Aktualny index medzicasu */
  private actualSplitIndex = 0

  /** Tween pre medzicas */
  private afterSplitTween: gsap.core.Tween | undefined

  /** posledny rozdiel v medzicase */
  private lastDifference = 0

  /**
   * Konstruktor
   */
  public constructor() {

    this.splitInfo = gatesConfig.split.map((val, i) => ({ splitIndex: i,
      gateIndex: val }))
    this.splitCount = this.splitInfo.length

  }

  /**
   * getter
   * @returns splitCount
   */
  public getSplitCount(): number {

    return this.splitCount

  }

  /**
   * Nastavenie najlepsich medzicasov
   * @param data - Data medzicasov
   */
  public setBestSplit(data: number[]): void {

    this.splitInfo = this.splitInfo.map((val, idx) => {

      if (data[idx]) {

        return { ...val,
          bestTime: data[idx] }

      }

      return val

    })

  }

  /**
   * Skontrolovanie aktualneho medzicasu a jeho zapisanie, ak presiel brankou daneho medzicasu
   * @param gateIndex - Index branky
   */
  public checkActualSplit(gateIndex: number): void {

    if (gateIndex === gatesConfig.count - 4) {

      this.manageStateBeforeFinish()

    }

    this.manageStateBeforeSplitTime(gateIndex)

    if (
      this.actualSplitIndex >= this.splitCount ||
            this.splitInfo[this.actualSplitIndex].gateIndex !== gateIndex
    ) return

    const actualTime = timeManager.getGameTimeWithPenaltyInSeconds(true)
    const bestTime = this.splitInfo[this.actualSplitIndex].bestTime

    // zaznamenavame medzicas
    this.splitInfo[this.actualSplitIndex].time = actualTime

    const difference = actualTime - (bestTime ?? actualTime)
    const differencePrefix = SplitTimeManager.getDifferencePrefix(difference)
    const differenceText = SplitTimeManager.formatDifferenceTime(difference, differencePrefix)

    this.manageStateOnSplitTime(bestTime ?? minigameConfig.dnfValue)

    this.splitInfo[this.actualSplitIndex].difference = differenceText

    // zapiseme do UI - TODO warn, ked bude UI, tak mozeme vymazat
    if (bestTime) {

      splitTimeState().addSplitTime({
        text: differenceText,
        color: SplitTimeManager.getColor(difference)
      })
      splitTimeState().actualTime = actualTime.toString()

    }

    // presunieme sa na dalsi medzicas
    this.actualSplitIndex++

    // pri treningu nechceme zahrat zvuk
    if (modes.isTrainingMode()) return

    if (differencePrefix === '-') {

      audioManager.play(AudioNames.checkpointFail)

    } else {

      audioManager.play(AudioNames.checkpointGood)

    }

    this.playCommentatorSplitTime(difference)

    this.lastDifference = difference

  }

  /**
   * pustime komentatora split time
   */
  private playCommentatorSplitTime(difference: number): void {

    let audio
    const bigger = difference > this.lastDifference

    if (this.actualSplitIndex === 1) {

      audio = AudioNames.commentSplitTimesFirstMinus
      if (difference > 0) audio = AudioNames.commentSplitTimesFirstPlus

    } else {

      if (this.lastDifference > 0 && difference > 0) {

        // deficit
        audio = AudioNames.commentSplitTimesDeficitDecreased
        if (bigger) audio = AudioNames.commentSplitTimesDeficitIncreased

      } else if (this.lastDifference <= 0 && difference <= 0) {

        // lead
        audio = AudioNames.commentSplitTimesLeadIncrease
        if (bigger) audio = AudioNames.commentSplitTimesLeadDecrease

      } else {

        // change
        audio = AudioNames.commentSplitTimesFromRedToGreen
        if (bigger) audio = AudioNames.commentSplitTimesFromGreenToRed

      }

    }

    audioManager.stopAudioByGroup(AudioGroups.commentators)
    audioManager.play(audio)

  }

  /**
   * Zistenie prefixu pre diff
   * @param difference - Diff
   * @returns Prefix
   */
  public static getDifferencePrefix(difference: number): string {

    let differencePrefix = ''
    if (difference > 0) differencePrefix = '+'
    if (difference < 0) differencePrefix = '-'
    return differencePrefix

  }

  /**
   * Vratenie farby pre medzicasove UI
   * @param difference - Rozdiel casu
   * @returns Farba
   */
  public static getColor(difference: number): string {

    return difference > 0 ? 'red' : 'green'

  }

  /**
   * Naformatovanie diffu casu
   * @param difference - diff
   * @param differencePrefix - prefix pre diff
   * @returns Naformatovany diff time
   */
  public static formatDifferenceTime(difference: number, differencePrefix: string): string {

    const timeInFormat = timeManager.getTimeInFormatFromSeconds(Math.abs(difference))
    return `${differencePrefix}${timeInFormat}`

  }

  /**
   * reset managera
   */
  public reset(): void {

    this.splitCount = 0
    this.actualSplitIndex = 0
    timeManager.reset()

  }

  /**
   * Vratenie vsetkych checkpointov
   * @returns Pole checkpointov
   */
  public getAllSplitTimes(): number[] {

    return this.splitInfo.map((value) => value.time || 0)

  }

  /**
   * zastavenie casu na casemiere a nastavenie tweenu na znovuspustenie
   * @param bestTime - number
   */
  public manageStateOnSplitTime(bestTime: number): void {

    if (modes.isTutorial() || modes.isTrainingMode()) return

    timeState().$patch({
      v2Expanded: false,
      showDiff: true,
      diffIndex: this.actualSplitIndex,
      isV1: true,
      doTimeUpdate: false,
      bestTime: bestTime,
      isFinish: false
    })

    this.afterSplitTween = gsap.to({}, {
      onComplete: () => {

        timeState().$patch({
          v2Expanded: false,
          showDiff: false,
          diffIndex: this.actualSplitIndex,
          isV1: true,
          doTimeUpdate: true,
          bestTime: bestTime,
          isFinish: false
        })

      },
      duration: 4
    })

  }

  /**
   * nastavenie odpocitavnia 2 branky pred split time
   * @param gateIndex - number
   */
  public manageStateBeforeSplitTime(gateIndex: number): void {

    if (modes.isTutorial() || modes.isTrainingMode()) return

    if (
      this.actualSplitIndex < this.splitCount &&
            (
              this.splitInfo[this.actualSplitIndex].gateIndex - 2 <= gateIndex &&
                this.splitInfo[this.actualSplitIndex].gateIndex > gateIndex
            )
    ) {

      timeState().$patch({
        v2Expanded: false,
        showDiff: false,
        diffIndex: this.actualSplitIndex,
        isV1: false,
        doTimeUpdate: true,
        bestTime: this.splitInfo[this.actualSplitIndex].bestTime ?? minigameConfig.dnfValue,
        isFinish: false
      })
      this.afterSplitTween?.kill()

    }

  }

  /**
   * nastavenie 3 branky pred cielom
   */
  public manageStateBeforeFinish(): void {

    if (modes.isTutorial() || modes.isTrainingMode()) return

    timeState().$patch({
      v2Expanded: true,
      showDiff: false,
      diffIndex: this.splitCount,
      isV1: false,
      doTimeUpdate: true,
      bestTime: minigameConfig.dnfValue,
      isFinish: false
    })
    this.afterSplitTween?.kill()

  }

}
