<template>
  <instructions-component
    class="pointer-events-auto midder"
    :header="$t('instructions')"
    :button-text="$t('continue')"
    :cards="getInstructions"
    show-button
    @button-click="closeInstructions"
  />
</template>

<script lang="ts">
import { Instructions } from '@powerplay/core-minigames-ui'
import {
  CorePhases,
  corePhasesManager,
  modes,
  tutorialManager
} from '@powerplay/core-minigames'
import { pathAssets } from '@/globals/globalvariables'

import { defineComponent } from 'vue'
import { blurState } from '@/stores'

export default defineComponent({
  name: 'InstructionComponent',
  components: {
    InstructionsComponent: Instructions
  },
  props: {
    isMobile: {
      type: Boolean,
      required: true
    }
  },
  emits: ['close-instructions'],
  data() {

    return {
      pathImages: pathAssets
    }

  },
  computed: {
    getInstructions(): {imgSrc: string, header: string, text: string[]}[] {

      // document.getElementsByTagName('canvas')[0].classList.add('blur-class')
      blurState().isActive = true

      if (modes.isTrainingMode()) {

        return [
          {
            imgSrc: `${pathAssets}/ui/instructions/training-1.jpg`,
            header: this.$t('instructionsDisciplineTraining6Cell1Title'),
            text: [this.$t('instructionsDisciplineTraining6Cell1Text1')]
          },
          {
            imgSrc: `${pathAssets}/ui/instructions/training-2-v2.jpg`,
            header: this.$t('instructionsDisciplineTraining6Cell2Title'),
            text: [this.$t('instructionsDisciplineTraining6Cell2Text1')]
          },
          {
            imgSrc: `${pathAssets}/ui/instructions/training-3.jpg`,
            header: this.$t('instructionsDisciplineTraining6Cell3Title'),
            text: [this.$t('instructionsDisciplineTraining6Cell3Text1')]
          }
        ]

      }

      const instructions = [
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-1-v2.jpg`,
          header: this.$t('instructionsDiscipline6Cell1Title'),
          text: [this.$t('instructionsDiscipline6Cell1Text1')]
        },
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-2-v2.jpg`,
          header: this.$t('instructionsDiscipline6Cell2Title'),
          text: [this.$t('instructionsDiscipline6Cell2Text1')]
        },
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-3-v2.jpg`,
          header: this.$t('instructionsDiscipline6Cell3Title'),
          text: [this.$t('instructionsDiscipline6Cell3Text1')]
        },
        {
          imgSrc: `${pathAssets}/ui/instructions/instructions-4.jpg`,
          header: this.$t('instructionsDiscipline6Cell4Title'),
          text: [this.$t('instructionsDiscipline6Cell4Text1')]
        }

      ]

      return instructions

    }
  },
  methods: {
    closeInstructions(): void {

      // pokial mame prvotne instrukcie, tak ich zavrieme
      if (corePhasesManager.isActivePhaseByType(CorePhases.instructions)) {

        corePhasesManager.getActualPhase()?.finishPhase()

      }
      this.$emit('close-instructions')
      tutorialManager.blockInputsManually()

    }
  }

})


</script>

<style lang="less" scoped>
.time-keeper {
    position: absolute;
    top: 17%;
    right: 1%;
}

.instruciton-card-photo{
    width: 343px;
    height: 229px;
}
</style>
