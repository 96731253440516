<template>
  <traffic-light-box
    v-if="startMessageState.showMessage"
    class="traffic-light-box"
    :color="startMessageState.messageColor"
    :text="$t(startMessageState.messageText)"
  />
</template>

<script lang="ts">
import { TrafficLightBox } from '@powerplay/core-minigames-ui'

import { defineComponent } from 'vue'
import { startMessageState } from '@/stores'

export default defineComponent({
  name: 'TrafficComponent',
  components: {
    TrafficLightBox
  },
  data() {

    return {
      startMessageState: startMessageState(),
    }

  }
})

</script>

<style lang="less" scoped>
.traffic-light-box{
    position: absolute;
    left: 50%;
    top: 5%;
}
</style>
