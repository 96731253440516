import {
  CANNON,
  game,
  modes,
  THREE,
  gsap,
  audioManager
} from '@powerplay/core-minigames'
import type {
  CannonNamedBody,
  CollisionEvent
} from '@powerplay/core-minigames'
import { trainingTasks } from './TrainingTasks'
import {
  Tasks,
  AudioNames
} from '../../types'
import { checkPointsPlaceConfig } from '../../config/checkpointsPlaceConfig'
import { gameConfig } from '@/app/config'
import { shinyTextsState } from '@/stores'

/**
 * Trieda pre treningove checkpointy
 */
export class CheckPointsManager {

  /** Mesh checkpoitov */
  private checkPointsMesh: THREE.Mesh[] = []

  /** Mesh glowov checkpoitov */
  private checkPointsGlowMesh: THREE.Mesh[] = []

  /** Fyzikalne body checkpoinov */
  private checkPointsBody: CannonNamedBody[] = []

  /** Pocet prejdenych checkpointov */
  private triggeredCheckpoints = 0

  /** Skupina meshov pre checkpointy */
  private meshesGroup = new THREE.Group()

  /** Gsapy */
  private gsapes: gsap.core.Tween[] = []

  /** Nazov meshu pre checkpoint */
  public CHECKPOINT_MESH_NAME = 'CheckPointStar003'

  /** nazov meshu pre glow checkpointu */
  public CHECKPOINT_GLOW_MESH_NAME = 'CheckpointGlow'

  /**
   * Vytvorenie kopca
   */
  public create(): void {

    if (!modes.isTrainingMode()) return

    console.log('VYTVARAM CHECKPOINTY....')

    this.createCheckPointsPhysics()

    console.log('CHECKPOINTY vytvorene....')

  }

  /**
   * Vytvorenie fyziky checkpointov
   */
  private createCheckPointsPhysics(): void {

    const checkPointMesh = game.getMesh(this.CHECKPOINT_MESH_NAME)
    const checkPointGlowMesh = game.getMesh(this.CHECKPOINT_GLOW_MESH_NAME)

    for (const [key, value] of Object.entries(checkPointsPlaceConfig)) {

      const clonedMesh = checkPointMesh.clone()
      const clonedGlowMesh = checkPointGlowMesh.clone()
      clonedMesh.name = key
      clonedGlowMesh.name = `${key}Glow`
      const { x, y, z } = value.position
      clonedMesh.position.set(x, y - 1, z)
      clonedGlowMesh.position.set(x, y - 3, z)
      const { _x, _y, _z } = value.rotation
      clonedMesh.rotation.set(_x, _y, _z)
      clonedGlowMesh.rotation.set(_x, _y, _z)
      // clonedGlowMesh.scale.set(1, 1, 1)
      clonedGlowMesh.scale.set(2, 1, 2)
      clonedMesh.updateMatrix()
      clonedGlowMesh.updateMatrix()

      this.checkPointsMesh.push(clonedMesh)
      this.checkPointsGlowMesh.push(clonedGlowMesh)

      const checkPointPhysicsMaterial: CANNON.Material = new CANNON.Material(key)

      const shape = new CANNON.Cylinder(
        gameConfig.checkpointsDiameter,
        gameConfig.checkpointsDiameter,
        10
      )
      const physicsBody = new CANNON.Body({
        shape,
        mass: 0,
        material: checkPointPhysicsMaterial,
        isTrigger: true,
        collisionFilterGroup: 3,
        collisionFilterMask: 3,
        type: CANNON.BODY_TYPES.STATIC
      }) as CannonNamedBody

      physicsBody.name = key

      physicsBody.position.set(
        clonedMesh.position.x,
        clonedMesh.position.y,
        clonedMesh.position.z
      )

      physicsBody.addEventListener('collide', (e: CollisionEvent) => {

        if (
          e.body.name?.includes('collisionPlayerBody') &&
                    e.body.collisionFilterGroup === physicsBody.collisionFilterGroup
        ) {

          physicsBody.collisionFilterGroup = 99
          this.triggerCheckpoint()
          this.hideObjectAfterCollect(physicsBody)

        }

      })

      game.physics.addBody(physicsBody)
      this.checkPointsBody.push(physicsBody)

    }

    this.addMeshesInGroup()

  }

  /**
   * Schovanie objektu po prejdeni
   * @param physBody - Fyzicke body
   */
  private hideObjectAfterCollect(physBody: CannonNamedBody) {

    if (!physBody.name) return

    const mesh1 = this.meshesGroup.getObjectByName(physBody.name)
    const mesh2 = this.meshesGroup.getObjectByName(`${physBody.name}Glow`)
    if (mesh1) mesh1.visible = false // lepsie riesenie
    if (mesh2) mesh2.visible = false // lepsie riesenie
    // game.physics.remove(physBody)

  }

  /**
   * Pridanie meshov do skupiny
   */
  private addMeshesInGroup(): void {

    this.meshesGroup = new THREE.Group()
    this.meshesGroup.name = 'TrackMarkerMeshes'

    this.checkPointsMesh.forEach(mesh => {

      this.meshesGroup.add(mesh)

    })

    this.checkPointsGlowMesh.forEach(mesh => {

      this.meshesGroup.add(mesh)

    })

    game.scene.add(this.meshesGroup)

  }

  /**
   * Prejdenie checkpointom
   */
  private triggerCheckpoint(): void {

    this.triggeredCheckpoints += 1
    trainingTasks.countTaskValue(Tasks.checkpoints, this.triggeredCheckpoints)

    audioManager.play(AudioNames.checkpointGood)
    shinyTextsState().setTextByName({
      name: 'plus-5-pc',
      active: true
    })

    gsap.to({}, {
      onComplete: () => {

        shinyTextsState().setTextByName({
          name: 'plus-5-pc',
          active: false
        })

      },
      duration: 1
    })

  }

  /**
   * Reset checkpointov
   */
  public resetCheckpoints(): void {

    this.triggeredCheckpoints = 0

    this.checkPointsMesh.forEach(mesh => {

      mesh.visible = true

      mesh.matrixAutoUpdate = true

    })

    this.checkPointsGlowMesh.forEach(mesh => {

      mesh.visible = true

      mesh.matrixAutoUpdate = true

    })

    this.checkPointsBody.forEach(body => {

      body.collisionFilterGroup = 2

    })

  }

}

export const checkpointManager = new CheckPointsManager()
